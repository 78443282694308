var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.size + "em",
        height: _vm.size + "em",
        viewBox: "0 0 20 20",
        fill: "none",
      },
    },
    [
      _c("rect", {
        attrs: { width: "20", height: "20", rx: "5", fill: "white" },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M4.16699 10H15.8337",
          stroke: "#646464",
          "stroke-width": "1.66667",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }