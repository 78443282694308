var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row mt-5" }, [
      _c("div", { staticClass: "col pl-0" }, [
        _c("h2", [_vm._v(_vm._s(_vm.$t("_logbook_title_pro_parameters")))]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _vm.logbookParameters.hba1c
        ? _c(
            "div",
            { staticClass: "col-sm-12 col-md-4 col-lg-4 d-flex pl-0" },
            [
              _c("label", { staticClass: "mt-auto mb-2 mr-3" }, [
                _vm._v("HbA1c"),
              ]),
              _vm._v(" "),
              _c("Input", {
                attrs: {
                  name: "hba1c",
                  id: "hba1c",
                  type: "number",
                  onwheel: "return false;",
                  label: "",
                },
                on: {
                  input: function ($event) {
                    return _vm.updateData()
                  },
                },
                model: {
                  value: _vm.savedParameters.patient_inputs.hba1c,
                  callback: function ($$v) {
                    _vm.$set(_vm.savedParameters.patient_inputs, "hba1c", $$v)
                  },
                  expression: "savedParameters.patient_inputs.hba1c",
                },
              }),
              _vm._v(" "),
              _c("p", { staticClass: "mt-auto mb-2 ml-3" }, [_vm._v("%")]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-sm-12 col-md-5 col-lg-5 d-flex" },
        [
          _c("label", { staticClass: "mt-auto mb-2 mr-3" }, [
            _vm._v("Date de prise"),
          ]),
          _vm._v(" "),
          _c("Input", {
            attrs: { name: "date_parameter", type: "date", label: "" },
            on: {
              input: function ($event) {
                return _vm.updateData()
              },
            },
            model: {
              value: _vm.savedParameters.date,
              callback: function ($$v) {
                _vm.$set(_vm.savedParameters, "date", $$v)
              },
              expression: "savedParameters.date",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _vm.logbookParameters.weight
        ? _c(
            "div",
            { staticClass: "col-sm-12 col-md-4 col-lg-4 d-flex pl-0" },
            [
              _c("label", { staticClass: "mt-auto mb-2 mr-3" }, [
                _vm._v(_vm._s(_vm.$t("_weight"))),
              ]),
              _vm._v(" "),
              _c("Input", {
                attrs: { name: "weight", id: "weight", label: "" },
                on: {
                  input: function ($event) {
                    return _vm.updateData()
                  },
                },
                model: {
                  value: _vm.savedParameters.patient_inputs.weight,
                  callback: function ($$v) {
                    _vm.$set(_vm.savedParameters.patient_inputs, "weight", $$v)
                  },
                  expression: "savedParameters.patient_inputs.weight",
                },
              }),
              _vm._v(" "),
              _c("p", { staticClass: "mt-auto mb-2 ml-3" }, [_vm._v("kg")]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.logbookParameters.height
        ? _c(
            "div",
            { staticClass: "col-sm-12 col-md-4 col-lg-4 d-flex pl-0" },
            [
              _c("label", { staticClass: "mt-auto mb-2 mr-3" }, [
                _vm._v(_vm._s(_vm.$t("_height"))),
              ]),
              _vm._v(" "),
              _c("Input", {
                attrs: { name: "height", id: "height", label: "" },
                on: {
                  input: function ($event) {
                    return _vm.updateData()
                  },
                },
                model: {
                  value: _vm.savedParameters.patient_inputs.height,
                  callback: function ($$v) {
                    _vm.$set(_vm.savedParameters.patient_inputs, "height", $$v)
                  },
                  expression: "savedParameters.patient_inputs.height",
                },
              }),
              _vm._v(" "),
              _c("p", { staticClass: "mt-auto mb-2 ml-3" }, [_vm._v("cm")]),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }