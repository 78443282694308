<template>
    <Modal
        :title="$t('_confirmation')"
        :isOpen="isOpen"
        @close="$emit('close')"
    >

        <div v-if="(typeof(solutions) !== 'undefined' && solutions.includes(6))">
            <h3 class="pt-0">{{ $t('_224') }}</h3>
            <p>
                {{ $t('mdc_registration') }}
            </p>
        </div>

        <div v-if="(typeof(solutions) !== 'undefined' && solutions.includes(4))">
            <h3 class="pt-0">{{ $t('_95') }}</h3>
            <p>
                {{ $t('cgm_registration') }}
            </p>
        </div>

        <p v-else>
            {{ $t('registration_over') }}
        </p>

        <button
            class="btn"
            type="button"
            @click="$emit('close')"
        >
            {{ $t('confirm') }}
        </button>
    </Modal>
</template>

<script>
import Modal from '@components/modals/Modal';

export default {
    components: { Modal },

    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },

        context: {
            type: String,
            required: true,
        },

        solutions: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}
</style>
