var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "input input-colapsed mt-0 mb-3" }, [
    _c(
      "button",
      {
        staticClass: "btn",
        attrs: { type: "submit", disabled: _vm.disabled, readonly: false },
        on: { click: _vm.onClick },
      },
      [_vm._v("\n        " + _vm._s(_vm.label) + "\n    ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }