var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isOpen
    ? _c(
        "div",
        [
          _c("transition", { attrs: { name: "modal" } }, [
            _c("div", { staticClass: "modal-mask" }, [
              _c("div", { staticClass: "modal-wrapper" }, [
                _c(
                  "div",
                  { staticClass: "modal-dialog", attrs: { role: "document" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "modal-content",
                        class: _vm.modalObjectif ? "modal-scroll" : "",
                      },
                      [
                        _c("div", { staticClass: "modal-header" }, [
                          _c("h2", { staticClass: "modal-title" }, [
                            _vm._v(_vm._s(_vm.title)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "close",
                              attrs: {
                                type: "button",
                                "data-dismiss": "modal",
                                "aria-label": "close",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { "aria-hidden": "true" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("close")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    ×\n                                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "modal-body" },
                          [_vm._t("default")],
                          2
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }