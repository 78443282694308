var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-section h-100" }, [
    _c("h2", [_vm._v(_vm._s(_vm.$t("_23")))]),
    _vm._v(" "),
    _c("div", [
      _c("p", { staticClass: "font-weight-bold mt-4 mb-1" }, [
        _vm._v(_vm._s(_vm.$t("_24"))),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "nbSmsReceived" }, [_vm._v(_vm._s(_vm.AllView))]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col" }, [
        _c(
          "table",
          {
            staticClass: "table table--user table-striped",
            attrs: { id: "mail_history" },
          },
          [
            _c("thead", [
              _c("tr", [
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("_25"))),
                ]),
                _vm._v(" "),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("_26"))),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.medias, function (media, key) {
                return _c("tr", { key: key }, [
                  _c("td", [_vm._v(_vm._s(media.media_name))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(media.ViewCount))]),
                ])
              }),
              0
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }