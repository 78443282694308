<template>
  <fieldset :disabled="loading">
    <legend>
      <h2 class="mb-0">{{ $t("_83") }}</h2>
    </legend>

    <InputGroup :label="$t('_147')" required>
      <Checkbox
        v-model="form.diabetes.active"
        name="hasDiabetes"
        id="conditionDiabetes"
        :label="$t('_148')"
        :disabled="!CanInsertPatientCroOrHcp || (canView.diabeteData != '' && canView.diabeteData) || (notMatch.diabete != '' && notMatch.diabete)"
      />

      <Checkbox
        v-model="form.hypertension.active"
        name="hasHypertension"
        id="conditionHypertension"
        :label="$t('_149')"
        :disabled="context === 'cro' || !hcp.has_hta || (canView.htaData != '' && canView.htaData) || (notMatch.hta != '' && notMatch.hta) || isPatientMinor"
      />
    </InputGroup>

    <span>
      <div>
        <legend>
          <h2 class="mb-0" :class="{ 'disabled-text': !form.diabetes.active}" v-if="notMatch.diabete == ''">Profil médical Diabète</h2>
          <h2 class="mb-0 disabled-text" v-if="notMatch.diabete != '' && notMatch.diabete">
            Profil médical Diabète : Ce profil médical a déjà été rempli par l'un de vos confrères. Pour des questions de sécurité, vous ne pouvez pas le consulter.
          </h2>
        </legend>      
      </div>
    </span>
    <hr>
    <div v-if="form.diabetes.active || (canView.diabeteData != '' && canView.diabeteData)">
    <!-- Profil médical Diabète -->
      <div>

          <Input
              v-model="form.diabetes.code"
              :label="$t('_166')"
              name="hcpCode"
              :readonly="context === 'hcp'"
              :custom-margin='true'
              required
          />

        <InputGroup :label="$t('_150')" required>
          <Radio
            v-model="form.diabetes.type"
            :value="1"
            name="diabetesType"
            id="diabetesType1"
            :label="$t('_151')"
            @change="preChecked"
            :disabled="(canView.diabeteData != '' && canView.diabeteData)"
          />

          <Radio
            v-model="form.diabetes.type"
            :value="2"
            name="diabetesType"
            id="diabetesType2"
            :label="$t('_152')"
            @change="preChecked"
            :disabled="(canView.diabeteData != '' && canView.diabeteData)"
          />

          <Radio
            v-model="form.diabetes.type"
            :value="3"
            name="diabetesType"
            id="diabetesType3"
            :label="$t('_153')"
            @change="preChecked"
            :disabled="(canView.diabeteData != '' && canView.diabeteData)"
          />
        </InputGroup>

        <InputGroup :label="$t('_154')" required>
          <Checkbox
            v-model="form.diabetes.treatments"
            :value="1"
            name="diabetesTreatments[]"
            id="diabetesTreatment1"
            :label="$t('_155')"
            :disabled="form.diabetes.treatments.includes(3) || (canView.diabeteData != '' && canView.diabeteData)"
            :clear="form.diabetes.treatments.includes(3)"
            @change="preChecked"
          />

          <Checkbox
            v-model="form.diabetes.treatments"
            :value="2"
            name="diabetesTreatments[]"
            id="diabetesTreatment2"
            :label="$t('_156')"
            :disabled="canView.diabeteData != '' && canView.diabeteData"
          />

          <!-- TODO disabled for now
            <Checkbox
                v-model="form.diabetes.treatments"
                :value="treatments['Toujeo']"
                name="diabetesTreatments[]"
                id="diabetesTreatment3"
                :label="$t('_157')"
                :disabled="form.diabetes.treatments.includes(1)"
                :clear="form.diabetes.treatments.includes(1)"
          />-->
          <Checkbox
            v-model="form.diabetes.treatments"
            :value="3"
            name="diabetesTreatments[]"
            id="diabetesTreatment3"
            :label="$t('_157')"
            :disabled="!false"
            :clear="!false"
          />
        </InputGroup>

        <InputGroup :label="$t('_158')" required>
          <Radio
            v-model="form.diabetes.insulinLessThan3Months"
            value="1"
            name="insulinLessThan3Months"
            id="insulinTrue"
            :label="$t('_159')"
            @change="preChecked"
            :disabled="(canView.diabeteData != '' && canView.diabeteData)"
          />

          <Radio
            v-model="form.diabetes.insulinLessThan3Months"
            value="0"
            name="insulinLessThan3Months"
            id="insulinFalse"
            :label="$t('_160')"
            @change="preChecked"
            :disabled="(canView.diabeteData != '' && canView.diabeteData)"
          />
        </InputGroup>
      </div>
      <!--start solutions-->
      <legend>
        <h2 class="mb-0">{{ $t("_98") }}</h2>
      </legend>
      <div>
        <div class="row">
          <div class="col-12 asteriskRequired">{{ $t("_215") }}</div>
          <div class="col-md-6 col-lg-4">
            <h3 class="label-multisolutions solution-education">
              {{ $t("_99") }}
            </h3>

            <InputGroup required>
              <!-- Virtual Starter Kit -->
              <Checkbox
                v-model="form.solutions"
                value="1"
                name="educationSolutions"
                id="educationSolution1"
                :label="$t('_223')"
                :tooltip="$t('_tooltip_virtual_starter_kit')"
                :disabled="form.diabetes.insulinLessThan3Months === '0' || (canView.diabeteData != '' && canView.diabeteData)"
                :clear="form.diabetes.insulinLessThan3Months === '0'"
              />

              <!-- Diabeduc -->
              <Checkbox
                v-model="form.solutions"
                value="2"
                name="educationSolutions"
                id="educationSolutions2"
                :label="$t('_221')"
                :tooltip="$t('tooltip_psp_phone_email')"
                :disabled="form.diabetes.insulinLessThan3Months === '1' || (canView.diabeteData != '' && canView.diabeteData)"
                :clear="form.diabetes.insulinLessThan3Months === '1'"
              />
            </InputGroup>
          </div>

          <div class="col-md-6 col-lg-4">
            <h3 class="label-multisolutions solution-monitoring">
              {{ $t("_100") }}
            </h3>

            <InputGroup>
              <!-- My Dose Coach -->
              <Checkbox
                v-model="form.solutions"
                value="6"
                name="monitoringSolutions[]"
                id="monitoringSolution3"
                :label="$t('_224')"
                :tooltip="$t('_tooltip_mdc')"
                :disabled="(form.solutions.includes('4') || form.solutions.includes('5')) || (form.solutions.includes(4) || form.solutions.includes(5)) || ((form.diabetes.type === 1 || form.diabetes.type === 3) && (form.diabetes.treatments.includes(1))) || (typeof(form.solutions) !== 'undefined' && form.solutions.includes('4')) || ((form.diabetes.type === 3 || form.diabetes.type === 2 || form.diabetes.type === 1) && (form.diabetes.treatments.includes(2) && !form.diabetes.treatments.includes(1))) || (form.diabetes.treatments.includes(2) && form.diabetes.treatments.includes(1) && form.diabetes.programs == 1) || (form.diabetes.type === 2 && form.diabetes.treatments.includes(1) && form.diabetes.treatments.includes(2))"
                :clear="(form.solutions.includes('4') || form.solutions.includes('5')) || (form.solutions.includes(4) || form.solutions.includes(5)) || ((form.diabetes.type === 1 || form.diabetes.type === 3) && (form.diabetes.treatments.includes(1))) || (typeof(form.solutions) !== 'undefined' && form.solutions.includes('4')) || ((form.diabetes.type === 3 || form.diabetes.type === 2 || form.diabetes.type === 1) && (form.diabetes.treatments.includes(2) && !form.diabetes.treatments.includes(1))) || (canView.diabeteData != '' && canView.diabeteData)"
              />

              <!-- Digital Logbook -->
              <Checkbox
                v-model="form.solutions"
                value="5"
                name="monitoringSolutions[]"
                id="monitoringSolution2"
                :label="$t('_225')"
                :tooltip="$t('_tooltip_digital_logbook')"
                :disabled="(form.solutions.includes('4') || form.solutions.includes('6')) || (form.solutions.includes(4) || form.solutions.includes(6)) || (canView.diabeteData != '' && canView.diabeteData)"
                :clear="(form.solutions.includes('4') || form.solutions.includes('6')) || (form.solutions.includes(4) || form.solutions.includes(6))"
              />

              <!-- Continous Glucose Monitoring -->
              <Checkbox
                v-model="form.solutions"
                value="4"
                name="monitoringSolutions[]"
                id="monitoringSolution1"
                :label="$t('_226')"
                :tooltip="$t('_tooltip_cgm')"
                :disabled="(form.solutions.includes('6') || form.solutions.includes('5')) || (form.solutions.includes(6) || form.solutions.includes(5)) || (typeof(form.solutions) !== 'undefined' && form.solutions.includes('6')) || ((form.diabetes.type === 3 || form.diabetes.type === 2 || form.diabetes.type === 1) && (form.diabetes.treatments.includes(2) && !form.diabetes.treatments.includes(1)))"
                :clear="(form.solutions.includes('6') || form.solutions.includes('5')) || (form.solutions.includes(6) || form.solutions.includes(5)) || (typeof(form.solutions) !== 'undefined' && form.solutions.includes('6')) || ((form.diabetes.type === 3 || form.diabetes.type === 2 || form.diabetes.type === 1) && (form.diabetes.treatments.includes(2) && !form.diabetes.treatments.includes(1))) || (canView.diabeteData != '' && canView.diabeteData)"
              />
            </InputGroup>
            <!--end solutions-->
          </div>
        </div>
      </div>
      <!-- Diabete Program--> 
      <InputGroup
        :label="$t('_161')"
        required
      >
        <Checkbox
          v-model="form.diabetes.programs"
          :value="1"
          name="diabetesPrograms[]"
          id="diabetesProgram1"
          :label="$t('_162')"
          :tooltip="$t('tooltip_diab_ecare')"
          :disabled="!form.diabetes.active"
          :clear="!form.diabetes.active"
          @change="$emit('programChange')"
        />

        <Checkbox
          v-model="form.diabetes.programs"
          :value="2"
          name="diabetesPrograms[]"
          id="diabetesProgram2"
          :label="$t('_163')"
          :tooltip="$t('tooltip_psp_phone_email')"
          :disabled="!false"
          :clear="!false"
        />
      </InputGroup>
    </div>
    <!-- End profil médical Diabète -->

    <span>
      <div>
        <legend>
          <h2 class="mb-0" :class="{ 'disabled-text': !form.hypertension.active}" v-if="notMatch.hta == '' && !isPatientMinor && context === 'hcp'">Profil médical Hypertension</h2>
          <h2 class="mb-0 disabled-text" v-if="(notMatch.hta != '' && notMatch.hta) && context === 'hcp'">
            Profil médical Hypertension : Ce profil médical a déjà été rempli par l'un de vos confrères. Pour des questions de sécurité, vous ne pouvez pas le consulter.
          </h2>
          <h2 class="mb-0 disabled-text" v-if="isPatientMinor && context === 'hcp'">
            Profil médical Hypertension : Le patient doit avoir 18 ans et plus, merci de revoir sa date de naissance pour poursuivre l'inscription.
          </h2>
          <h2 class="mb-0 disabled-text" v-if="context === 'cro' && !canView.htaData">
            Profil médical Hypertension : Vous n'avez pas les droits pour inscrire le patient au programme support patient HTA eCare.
          </h2>
          <h2 class="mb-0 disabled-text" v-if="context === 'cro' && canView.htaData != '' && canView.htaData">
            Profil médical Hypertension : Ce profil médical a déjà été rempli par un professionnel de santé. Pour des questions de sécurité, vous ne pouvez pas le consulter.
          </h2>
        </legend>      
      </div>
    </span>
    <hr>
    <!-- start profil médical Hypertension-->
    <div v-if="form.hypertension.active">
      <div  id="hypertension">
        <Input
              v-model="form.hypertension.code"
              :label="$t('_166')"
              name="hcpCode"
              :readonly="context === 'hcp'"
              :custom-margin='true'
              required
          />

        <InputGroup label="Tabagisme" required>
          <Radio
            v-model="form.hypertension.data.smoking"
            value="1"
            name="smoking"
            id="smoking1"
            label="Oui"
          />

          <Radio
            v-model="form.hypertension.data.smoking"
            value="0"
            name="smoking"
            id="smoking0"
            label="Non"
          />
        </InputGroup>

        <InputGroup
        v-if="true"
        :label="$t('_hypertension_treatments') + ' :'"
        required
        >
          <Checkbox
            v-model="form.hypertension.treatments"
            :value="4"
            name="hypertensionTreatments[]"
            id="hypertensionTreatment1"
            label="APROVEL ®"
          />

          <Checkbox
            v-model="form.hypertension.treatments"
            :value="6"
            name="hypertensionTreatments[]"
            id="hypertensionTreatment2"
            label="APROVASC ®"
          />

          <Checkbox
            v-model="form.hypertension.treatments"
            :value="9"
            name="hypertensionTreatments[]"
            id="hypertensionTreatment3"
            label="CO-APROVEL ®"
          />

          <Checkbox
            v-model="form.hypertension.treatments"
            :value="12"
            name="hypertensionTreatments[]"
            id="hypertensionTreatment4"
            label="TRIATEC ®"
          />

          <Checkbox
            v-model="form.hypertension.treatments"
            :value="16"
            name="hypertensionTreatments[]"
            id="hypertensionTreatment5"
            label="TRITAZIDE ®"
          />
        </InputGroup>
        
        <InputGroup
        v-if="true"
        :label="$t('_antihypertensive_less_than_6_months') + ' :'"
        required
        >
          <Radio
            v-model="form.hypertension.data.antihypertensive_less_than_6_months"
            value="1"
            name="antihypertensveLessThan6Months"
            id="antihypertensiveTrue"
            :label="$t('_159')"
          />

          <Radio
            v-model="form.hypertension.data.antihypertensive_less_than_6_months"
            value="0"
            name="antihypertensveLessThan6Months"
            id="antihypertensiveFalse"
            :label="$t('_160')"
          />
        </InputGroup>

        <InputGroup
        :label="$t('_161')"
        required
        >
          <Checkbox
            v-model="form.hypertension.programs"
            :value="3"
            name="hypertensionPrograms[]"
            id="hypertensionProgram1"
            :tooltip="$t('tooltip_hta_psp_phone_email')"
            :label="$t('_222')"
          />
        </InputGroup>

        <!--Program support patient-->
        <legend>
          <h2 class="mb-0">{{ $t("_98") }}</h2>
        </legend>

        <div class="row">
          <div class="col-12 asteriskRequired">
            {{ $t("_multi_solutions_hta") }}
          </div>

          <div class="col-md-6 col-lg-4">
            <h3 class="label-multisolutions solution-education">
              {{ $t("_99") }}
            </h3>

            <InputGroup required>
              <!-- HTA Educ Digital -->
              <Checkbox
                v-model="form.solutions"
                :value="7"
                name="educationSolutions[]"
                id="educationSolution5"
                :tooltip="$t('tooltip_hta_educ')"
                :label="$t('_hta_educ_digital')"
              />
            </InputGroup>
          </div>
        </div>  
      </div>
    </div>
    <!-- end profil médical Hypertension-->
  </fieldset>
</template>

<script>
import InputGroup from "@components/inputs/InputGroup";
import Input from "@components/inputs/Input";
import Radio from "@components/inputs/Radio";
import Checkbox from "@components/inputs/Checkbox";
import Submit from "@components/inputs/Submit";

export default {
  components: {
    InputGroup,
    Input,
    Radio,
    Checkbox,
    Submit
  },

  props: {
    form: {
      type: Object,
      required: true
    },
    context: {
      type: String,
      required: true,
    },
    rulesData: {
      type: Object,
      required: true
    },
    treatments: {
      type: Object,
      required: true
    },
    hcp: {
      type: Object,
      required: false
    },
    isPatientMinor: {
      type: Boolean,
      required: false
    },
    canView: {
      type: Object,
      required: false
    },
    notMatch: {
      type: Object,
      required: false
    },
    programs: {
      type: [Object, Array],
      required: true
    },

    loading: {
      type: Boolean,
      default: false
    },

    edit: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      diabetesVirtualStarterKit: true,
      diabetesDiabeduc: true,
      diabetesMyDoseCoach: true,
      diabetesDigitalLogbook: true,
      diabetesContinuousGlucoseMonitoring: true,
      htaUpdate:false,
      diabeteUpdate:false,
      url: window.location.pathname,
    };
  },

  computed: {
    treatmentsForHypertension: function() {
      let excluded = ["Lantus", "Apidra", "Toujeo"];

      let objectToReturn = {};

      Object.keys(this.treatments).forEach(key => {
        if (!excluded.includes(key)) {
          objectToReturn[key] = this.treatments[key];
        }
      });

      return objectToReturn;
    },
    isHtaUpdate(){
      this.htaUpdate = sessionStorage.getItem('fds') == 'hta' ? true : false;
      if (this.url === '/professionnel/patient_medical_form.php' && this.htaUpdate) {
        $('#conditionDiabetes').prop('disabled', true);
      }
    },
    isDiabeteUpdate(){
      this.diabeteUpdate = sessionStorage.getItem('fds') == 'diabete' ? true : false;
      if (this.url === '/professionnel/patient_medical_form.php' && this.diabeteUpdate) {
        $('#conditionHypertension').prop('disabled', true);
      }
    },
    CanInsertPatientCroOrHcp(){
      if (this.hcp.hasOwnProperty('has_diabete')) {
        return this.hcp.has_diabete;
      }

      return true;
    },
  },

  methods: {
    emitProgramChange() {
      eventBus.$emit("program-change");
    },

    disableAndClearDiabetesVirtualStarterKit() {
      this.diabetesVirtualStarterKit =
        this.form.diabetes.insulinLessThan3Months === "0" ||
        (typeof(this.form.solutions) !== 'undefined' && this.form.solutions.includes("3")) ||
        (typeof(this.form.solutions) !== 'undefined' && this.form.solutions.includes("2"));
    },

    disableAndClearDiabetesDiabeduc() {
      this.diabetesDiabeduc = this.form.diabetes.insulinLessThan3Months === "1";
    },

    disableAndClearDiabetesMyDoseCoach() {
      this.diabetesMyDoseCoach = !(
        this.form.diabetes.type == 2 &&
        (this.form.diabetes.treatments.includes(1) ||
          this.form.diabetes.treatments.includes("1"))
      );
    },

    disableAndClearDiabetesDigitalLogbook() {
      // This option is disabled for now
      // see: https://trello.com/c/M6OklK7E/32-inscrire-un-patient-programme-support-patient
      return true || form.solutions.includes("4");
    },

    disableAndClearDiabetesContinuousGlucoseMonitoring() {
      if(typeof(this.form.solutions) !== 'undefined'){
        this.diabetesContinuousGlucoseMonitoring = this.form.solutions.includes(
          "5"
        );
      }
    },
    preChecked(){
      // this.form.solutions = [];
    },
    //For pre selecting Diab eCare program
    preCheckedProgram(){
      this.form.diabetes.programs.push(1);
      this.form.hypertension.programs.push(3);
    },

    preCheckedSolution(){
      this.form.solutions.push(7);
    },
  },

  watch: {
    form: {
      handler: function(val, oldVal) {
        this.disableAndClearDiabetesVirtualStarterKit();
        this.disableAndClearDiabetesDiabeduc();
        this.disableAndClearDiabetesMyDoseCoach();
        this.disableAndClearDiabetesDigitalLogbook();
        this.disableAndClearDiabetesContinuousGlucoseMonitoring();
      },
      deep: true
    }
  },

  mounted() {
    this.preCheckedProgram();
    this.preCheckedSolution();
    this.diabetesVirtualStarterKit = this.disableAndClearDiabetesVirtualStarterKit();
    this.diabetesDiabeduc = this.disableAndClearDiabetesDiabeduc();
    this.diabetesMyDoseCoach = this.disableAndClearDiabetesMyDoseCoach();
    this.diabetesContinuousGlucoseMonitoring = this.disableAndClearDiabetesContinuousGlucoseMonitoring();
    this.isDiabeteUpdate;
    this.isHtaUpdate;
  }
};
</script>
<style scoped>
  .disabled-text {
    color: #999999;
    cursor: not-allowed; 
  }
</style>





