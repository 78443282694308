var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isOpen === true
    ? _c("div", [
        _c("div", { staticClass: "modal-mask" }, [
          _c("div", { staticClass: "modal-wrapper" }, [
            _c("div", { staticClass: "modal-container" }, [
              _c("div", { staticClass: "button_close_modal" }, [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.closeModal()
                      },
                    },
                  },
                  [_vm._v("X")]
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("h2", [_vm._v(_vm._s(_vm.$t("title_popup")))]),
                _vm._v(" "),
                _vm.link.status == 1
                  ? _c("p", [_vm._v(_vm._s(_vm.$t("text_popup_in_progress")))])
                  : _vm._e(),
                _vm._v(" "),
                _vm.link.status == 0
                  ? _c("p", [_vm._v(_vm._s(_vm.$t("text_popup_create")))])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "button_modal" }, [
                _c(
                  "a",
                  { staticClass: "btn", attrs: { href: _vm.link.action } },
                  [_vm._v(_vm._s(_vm.$t("_78")))]
                ),
              ]),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }