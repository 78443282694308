var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h1", [_vm._v(_vm._s(_vm.$t("questionnaire_title")))]),
    _vm._v(" "),
    _c("div", { staticClass: "page-section h-100" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", [
            _c("h2", [_vm._v(_vm._s(_vm.$t("questionnaire_sub_title")))]),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("p", [_vm._v(_vm._s(_vm.$t("questionnaire_para_1")))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.$t("questionnaire_para_2")))]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt-4" }, [
            _c("h2", [_vm._v(_vm._s(_vm.$t("questionnaire_info_1")))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.$t("questionnaire_info_2")))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.$t("questionnaire_info_3")))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.$t("questionnaire_info_4")))]),
          ]),
          _vm._v(" "),
          _c(
            "form",
            {
              staticClass: "bg-white rounded-lg",
              attrs: {
                enctype: "multipart/form-data",
                method: "post",
                action: this.url,
              },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "ol",
                _vm._l(_vm.forms, function (question, index) {
                  return _vm.forms.length > 0
                    ? _c("div", { key: question.id }, [
                        question.format === "introduction"
                          ? _c("li", { staticClass: "container_li" }, [
                              _c("label", { staticClass: "label_range" }, [
                                _vm._v(
                                  _vm._s(question["question_" + _vm.lang])
                                ),
                              ]),
                            ])
                          : question.format == "range-intro"
                          ? _c(
                              "div",
                              [
                                _c("li", { staticClass: "container_li" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(question["question_" + _vm.lang]) +
                                      "\n                "
                                  ),
                                ]),
                                _vm._v(" "),
                                question.format === "range-intro"
                                  ? _c("Range", {
                                      staticClass:
                                        "custom_container_range input_range",
                                      attrs: {
                                        name: "custom_range_input",
                                        minValue: question.possible_answers.min,
                                        maxValue: question.possible_answers.max,
                                        id: "myRange_" + question.id,
                                        val: question.answer
                                          ? question.answer
                                          : null,
                                      },
                                      model: {
                                        value: question.answer,
                                        callback: function ($$v) {
                                          _vm.$set(question, "answer", $$v)
                                        },
                                        expression: "question.answer",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                question.format === "free_text"
                                  ? _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: question.answer,
                                          expression: "question.answer",
                                        },
                                      ],
                                      attrs: { cols: "60", rows: "10" },
                                      domProps: { value: question.answer },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            question,
                                            "answer",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : question.format == "text"
                          ? _c("div", { staticClass: "container_li" }, [
                              _c("p", [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(question["question_" + _vm.lang]) +
                                    "\n                "
                                ),
                              ]),
                            ])
                          : _c(
                              "div",
                              {
                                staticClass: "container_li",
                                class: {
                                  "sub-question":
                                    question.previous_question_format == "text",
                                },
                              },
                              [
                                _c("label", { staticClass: "label_range" }, [
                                  _vm._v(
                                    _vm._s(question["question_" + _vm.lang])
                                  ),
                                ]),
                                _vm._v(" "),
                                question.format === "range"
                                  ? _c("Range", {
                                      staticClass:
                                        "custom_container_range input_range",
                                      attrs: {
                                        name: "custom_range_input",
                                        minValue: question.possible_answers.min,
                                        maxValue: question.possible_answers.max,
                                        id: "myRange_" + question.id,
                                        val: question.answer
                                          ? question.answer
                                          : null,
                                      },
                                      model: {
                                        value: question.answer,
                                        callback: function ($$v) {
                                          _vm.$set(question, "answer", $$v)
                                        },
                                        expression: "question.answer",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                question.format === "free_text"
                                  ? _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: question.answer,
                                          expression: "question.answer",
                                        },
                                      ],
                                      attrs: { cols: "60", rows: "10" },
                                      domProps: { value: question.answer },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            question,
                                            "answer",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                      ])
                    : _vm._e()
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "fieldset",
                [
                  _c("Submit", {
                    staticClass: "text-center",
                    attrs: { label: _vm.$t("_195") },
                  }),
                ],
                1
              ),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }