<template>
  <div class="page-section h-100">
    <h2>{{ $t('_23') }}</h2>
    <div>
      <p class="font-weight-bold mt-4 mb-1">{{ $t('_24') }}</p>
      <p class="nbSmsReceived">{{ AllView }}</p>
    </div>
    <div class="row mb-4">
      <div class="col">
        <table id="mail_history" class="table table--user table-striped">
          <thead>
            <tr>
              <th scope="col">{{ $t('_25') }}</th>
              <th scope="col">{{ $t('_26') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(media, key) in medias" :key="key">
              <td>{{ media.media_name }}</td>
              <td>{{ media.ViewCount }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DigitalTherapeuticEducation",

  props: {
    context: {
      type: String,
      required: true,
    },

    user: {
      type: Object,
      required: true,
    },

    uuid: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      medias: {},
      AllView: '',
    }
  },

  mounted() {
    const endpoint = 'ws/get_infos_media.php';

    const params = [
      '?patient_id=' + this.user.patient_id +
      '&uuid=' + this.uuid,
    ];

    this.$axios.get(endpoint + params)
      .then(response => {
        this.medias = response.data.data.medias
        this.AllView = response.data.data.AllViewCount.total_views
      })
      .catch(e => {
        console.error(e);
      });
  }
}
</script>

<style scoped>

</style>
