<template>
    <div v-if="isOpen">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content " :class="modalObjectif ? 'modal-scroll' : ''">
                            <div class="modal-header">
                                <h2 class="modal-title">{{ title }}</h2>
                                <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                    aria-label="close"
                                >
                                    <span
                                        aria-hidden="true"
                                        @click="$emit('close')"
                                    >
                                        &times;
                                    </span>
                                </button>
                            </div>

                            <div class="modal-body">
                                <slot></slot>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true,
        },

        isOpen: {
            type: Boolean,
            default: false,
        }, 
        modalObjectif: {
            type: Boolean, 
            default: false
        }
    },
};
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-title {
    padding: 0;
}

.modal-scroll {
    overflow-y: auto;
    height: 95vh;
}
</style>
