<template>
    <div class="input input-colapsed mt-0 mb-3">
        <label v-show="!!label"
            class="input-label"
            :class="{
                'asteriskRequired': required,
                'font-weight-bold': bold,
            }"
        >
            {{ label }}
        </label>

        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: false,
        },

        required: {
            type: Boolean,
            default: false,
        },

        bold: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped>
.radio-label::before {
    top: 0 !important;
}

.radio-label::after {
    top: 5px !important;
}
</style>
