var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "InputGroup",
    {
      attrs: {
        bold: "",
        label: _vm.$t("_193"),
        required: !!_vm.selectedPrograms,
      },
    },
    [
      _c(
        "p",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.selectedPrograms,
              expression: "!selectedPrograms",
            },
          ],
        },
        [_vm._v("\n      " + _vm._s(_vm.$t("_220")) + "\n    ")]
      ),
      _vm._v(" "),
      _c("Checkbox", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !!_vm.selectedPrograms,
            expression: "!!selectedPrograms",
          },
        ],
        attrs: {
          name: "consentPrograms",
          id: "consentPrograms",
          label: _vm.selectedPrograms,
        },
        model: {
          value: _vm.form.consent,
          callback: function ($$v) {
            _vm.$set(_vm.form, "consent", $$v)
          },
          expression: "form.consent",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }