var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.wrapper,
    { tag: "component", class: ["custom-range-wrapper"] },
    [
      _c(
        "div",
        {
          staticClass: "custom_range",
          staticStyle: { position: "relative", margin: "auto", width: "90%" },
        },
        [
          _vm.name
            ? _c(
                "output",
                {
                  staticClass: "custom-range-output",
                  staticStyle: {
                    position: "absolute",
                    "min-width": "100px",
                    "text-align": "center",
                  },
                  attrs: {
                    name: "custom-range-output",
                    id: "output_" + _vm.id,
                  },
                },
                [
                  _vm.rangeValue != null
                    ? _c("div", [_vm._v(_vm._s(_vm.rangeValue))])
                    : _c("div", [_vm._v("[]")]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", {
            staticClass: "left-range",
            attrs: { id: "left_range_" + _vm.id },
          }),
          _vm._v(" "),
          _vm.type === "checkbox" && _vm.id
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.rangeValue,
                    expression: "rangeValue",
                  },
                ],
                ref: "prev",
                class: ["custom-range"],
                attrs: {
                  id: _vm.id,
                  min: _vm.minValue,
                  max: _vm.maxValue,
                  step: _vm.step,
                  name: _vm.name,
                  disabled: _vm.disabled,
                  type: "checkbox",
                },
                domProps: {
                  value: _vm.val,
                  checked: Array.isArray(_vm.rangeValue)
                    ? _vm._i(_vm.rangeValue, _vm.val) > -1
                    : _vm.rangeValue,
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.rangeValue,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = _vm.val,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.rangeValue = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.rangeValue = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.rangeValue = $$c
                      }
                    },
                    function ($event) {
                      return _vm.onChange($event.target.value)
                    },
                  ],
                },
              })
            : _vm.type === "radio" && _vm.id
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.rangeValue,
                    expression: "rangeValue",
                  },
                ],
                ref: "prev",
                class: ["custom-range"],
                attrs: {
                  id: _vm.id,
                  min: _vm.minValue,
                  max: _vm.maxValue,
                  step: _vm.step,
                  name: _vm.name,
                  disabled: _vm.disabled,
                  type: "radio",
                },
                domProps: {
                  value: _vm.val,
                  checked: _vm._q(_vm.rangeValue, _vm.val),
                },
                on: {
                  change: [
                    function ($event) {
                      _vm.rangeValue = _vm.val
                    },
                    function ($event) {
                      return _vm.onChange($event.target.value)
                    },
                  ],
                },
              })
            : _vm.id
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.rangeValue,
                    expression: "rangeValue",
                  },
                ],
                ref: "prev",
                class: ["custom-range"],
                attrs: {
                  id: _vm.id,
                  min: _vm.minValue,
                  max: _vm.maxValue,
                  step: _vm.step,
                  name: _vm.name,
                  disabled: _vm.disabled,
                  type: _vm.type,
                },
                domProps: { value: _vm.val, value: _vm.rangeValue },
                on: {
                  change: function ($event) {
                    return _vm.onChange($event.target.value)
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.rangeValue = $event.target.value
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-between align-items-center",
            },
            [
              _c(
                "label",
                {
                  staticClass: "text-left",
                  attrs: { for: _vm.id + "_min_value" },
                },
                [
                  _vm._v(_vm._s(_vm.minLabel) + " "),
                  _c("span", { class: { "d-none": _vm.minLabel } }, [
                    _vm._v(_vm._s(_vm.minValue)),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "text-right",
                  attrs: { for: _vm.id + "_max_value" },
                },
                [
                  _vm._v(_vm._s(_vm.maxLabel) + " "),
                  _c("span", { class: { "d-none": _vm.maxLabel } }, [
                    _vm._v(_vm._s(_vm.maxValue)),
                  ]),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }