// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.radio-label[data-v-51c8d16c]::before {
    top: 0 !important;
}
.radio-label[data-v-51c8d16c]::after {
    top: 5px !important;
}
`, "",{"version":3,"sources":["webpack://./project/private/Resources/js/components/inputs/InputGroup.vue"],"names":[],"mappings":";AAsCA;IACA,iBAAA;AACA;AAEA;IACA,mBAAA;AACA","sourcesContent":["<template>\n    <div class=\"input input-colapsed mt-0 mb-3\">\n        <label v-show=\"!!label\"\n            class=\"input-label\"\n            :class=\"{\n                'asteriskRequired': required,\n                'font-weight-bold': bold,\n            }\"\n        >\n            {{ label }}\n        </label>\n\n        <slot></slot>\n    </div>\n</template>\n\n<script>\nexport default {\n    props: {\n        label: {\n            type: String,\n            required: false,\n        },\n\n        required: {\n            type: Boolean,\n            default: false,\n        },\n\n        bold: {\n            type: Boolean,\n            default: false,\n        },\n    },\n};\n</script>\n\n<style scoped>\n.radio-label::before {\n    top: 0 !important;\n}\n\n.radio-label::after {\n    top: 5px !important;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
