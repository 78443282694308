<template>
  <div>
    <h1>{{ $t('_logbook_title_pro_create') }} <span class="ml-2">{{ patient.forname }} {{ patient.name }}</span></h1>
    <div class="page-section h-100">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <h2>{{ $t('_logbook_title_pro_periode') }} <span class="color_asterisk">*</span></h2>
            </div>
            <div class="col-md-6 ">
              <!-- #TODO Change date -->
              <h2 class="text-right">{{ $t('_logbook_title_modified_date') }}
                {{ this.$moment(this.updateDate).locale("fr").format('L') }}</h2>
            </div>
          </div>
          <div class="row">
            <div v-for="(period, index) in periods" :key="index" class="col mx-auto mt-2">
              <div>
                <LogbookCard :ref="'period' + period.id" :id="period.id" :period="period" @openModal="openModal(period)"
                  @deletePeriod="deletePeriod(period)" />
              </div>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12">
              <h2>{{ $t('_logbook_title_pro_parameters') }}</h2>
              <fieldset>
                <label>{{ $t('_logbook_title_pro_hba1c') }} <span class="color_asterisk">*</span> </label>
                <InputGroup required>
                  <Radio v-model="form.hba1c" :value="true" name="hba1c" id="hba1c" :label="$t('_112')" />
                  <Radio v-model="form.hba1c" :value="false" name="hba1c1" id="hba1c1" :label="$t('_113')" />
                </InputGroup>
              </fieldset>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12">
              <fieldset>
                <InputGroup required>
                  <label>{{ $t('_logbook_title_pro_periodicite') }} </label>
                  <SelectGlobal v-model="form.periodicity" :datas="periodicite" />
                </InputGroup>
              </fieldset>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12">
              <fieldset>
                <label>{{ $t('_logbook_title_pro_anthropometric') }} </label>
                <InputGroup required>
                  <Checkbox v-model="form.weight" name="weight" id="weight" :label="$t('_weight')" />
                  <Checkbox v-model="form.height" name="height" id="height" :label="$t('_height')" />
                </InputGroup>
              </fieldset>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12">
              <fieldset>
                <label>{{ $t('_logbook_title_pro_hypoglycemique') }} <span class="color_asterisk">*</span></label>
                <InputGroup required>
                  <Radio v-model="form.glycemic_episode" :value="true" name="glycemic_episode" id="glycemic_episode"
                    :label="$t('_112')" />
                  <Radio v-model="form.glycemic_episode" :value="false" name="glycemic_episode1" id="glycemic_episode1"
                    :label="$t('_113')" />
                </InputGroup>
              </fieldset>
            </div>
          </div>
          <!--gestion_episodes_patient -->
          <div class="row mt-5">
            <div class="col-md-10">
              <h2>{{ $t('_logbook_pro_objectif_glycemique') }}</h2>
              <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-4 d-flex align-items-end">
                  <p class="mr-5">{{ $t('_glycemie_jeun_pro') }} <span class="color_asterisk">*</span></p>
                </div>
                <div class="col-sm-12 col-md-3 col-lg-3">
                  <CustomInput :text-before="$t('_from')" text-after="mg/dL" id="sugar_empty_from" name="sugar_empty_from"
                    v-model.number="objectives.blood_sugar_empty_stomach.from" />
                </div>
                <div class="col-sm-12 col-md-3 col-lg-3">
                  <CustomInput :text-before="$t('_to')" text-after="mg/dL" id="sugar_empty_to" name="sugar_empty_to"
                    v-model.number="objectives.blood_sugar_empty_stomach.to" />
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-4 d-flex align-items-end">
                  <p class="mr-5">{{ $t('_glycemie_post_prandial_pro') }} <span class="color_asterisk">*</span></p>
                </div>
                <div class="col-sm-12 col-md-3 col-lg-3">
                  <CustomInput :text-before="$t('_from')" text-after="mg/dL" id="sugar_postprandial_from"
                    name="sugar_postprandial_from" v-model.number="objectives.blood_sugar_postprandial.from" />
                </div>
                <div class="col-sm-12 col-md-3 col-lg-3">
                  <CustomInput :text-before="$t('_to')" text-after="mg/dL" id="sugar_postprandial_to"
                    name="sugar_postprandial_to" v-model.number="objectives.blood_sugar_postprandial.to" />
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-4 d-flex align-items-end">
                  <p class="mr-5">{{ $t('_logbook_title_pro_hba1c') }} <span class="color_asterisk">*</span></p>
                </div>
                <div class="col-sm-12 col-md-3 col-lg-3">
                  <CustomInput :text-before="$t('_between')" text-after="%" id="hba1c_between" name="hba1c_between"
                    v-model.number="objectives.hba1c.between" />
                </div>
                <div class="col-sm-12 col-md-3 col-lg-3">
                  <CustomInput :text-before="$t('_and')" text-after="%" id="hba1c_and" name="hba1c_and"
                    v-model.number="objectives.hba1c.and" />
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12">
              <h2>{{ $t('_recommendation_insuline') }}</h2>
            </div>
          </div>
          <div class="row mt-3">
            <div
              v-if="activeInsulinRecommandation.includes('Toujeo') || activeInsulinRecommandation.includes('Lantus')"
              class="col-md-6">
              <p class="font-weight-bold">{{ $t('_glycemie_jeun') }} -
                <span v-if="activeInsulinRecommandation.includes('Lantus')">{{
                  $t('_Lantus') }}</span>
                <span
                  v-if="activeInsulinRecommandation.includes('Lantus') && activeInsulinRecommandation.includes('Toujeo')">/</span>
                <span v-if="activeInsulinRecommandation.includes('Toujeo')">{{
                  $t('_Toujeo') }}</span>
              </p>
              <InsulinRecommandation :dataGlycemic="dataEmpty" @blockData="blockData($event)" :key="updateBlock + 1" />
            </div>
            <div v-if="activeInsulinRecommandation.includes('Apidra')" class="col-md-6">
              <p class="font-weight-bold">{{ $t('_glycemie_post_prandial') }} - {{ $t('_Apidra') }}</p>
              <InsulinRecommandation :dataGlycemic="dataPost" @blockData="blockDataPost($event)" :key="updateBlock + 2" />
            </div>
          </div>
          <div class="row mt-5 justify-content-center">
            <div></div>
            <div class="col-md-2 col-sm-12 text-center mt-2">
              <button class="btn btn-outline-primary" @click="cancel()">{{ $t('_cancel') }}</button>
            </div>
            <div class="col-md-5 col-sm-12 mt-2 text-center">
              <button class="btn" @click="submit()" :disabled="loadingState">
                <div
                    v-if="loadingState"
                    :style="{ color: '#cfbb97' }"
                    class="spinner-border spinner-border-sm"
                    role="status"
                >
                    <span class="sr-only">{{ $t('_93') }}</span>
                </div>
                <i v-else class="icon small">check</i>
                {{ $t('_confirm_glycemic_file') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalPeriods v-if="isOpen" :treatments="patient.treatments" :is-open="isOpen" :period-array="periodArray"
      :data="modalData" @close="closeModal($event)" @save="savePeriod($event)" />
  </div>
</template>

<script>
import LogbookCard from '@/components/inputs/LogbookCard';
import CustomInput from '@components/inputs/CustomInput';
import ModalPeriods from '@components/modals/ModalPeriods';
import periodData from '@components/json/periodData';
import InsulinRecommandation from '@/components/forms/InsulinRecommandation';
import apiCall from '../apiLogbook';


export default {
  name: "ProfessionnalLogBook",

  data() {
    return {
      loadingState: false,
      periods: periodData,
      patient: {},
      patient_id: sessionStorage.getItem('patient_id'),
      modalData: null,
      isOpen: false,
      updateDate: '',
      glycemic_logbook_id: '',
      periodData: null,
      updateBlock: 0,
      form: {
        hba1c: null,
        weight: false,
        height: false,
        glycemic_episode: null,
        periodicity: 3
      },
      objectives: {
        blood_sugar_empty_stomach: { from: null, to: null },
        blood_sugar_postprandial: { from: null, to: null },
        hba1c: { between: null, and: null }
      },
      dataEmpty: {},
      dataPost: {},
      periodArray: [],
      periodicite: [
        { text: this.$t('_3_month'), value: 3 },
        { text: this.$t('_6_month'), value: 6 },
        { text: this.$t('_12_month'), value: 12 }
      ],
      activeInsulinRecommandation: []
    }
  },
  components: {
    LogbookCard,
    ModalPeriods,
    InsulinRecommandation,
    CustomInput
  },
  mounted() {
    this.getLogbook();
    this.getPatient()
  },
  methods: {
    blockData(data) {
      this.dataEmpty = data === undefined ? {} : data;
    },
    blockDataPost(data) {
      this.dataPost = data === undefined ? {} : data;
    },
    getPatient() {
      apiCall.get('api/patients', this.patient_id)
        .then((response) => {
          this.patient = response.data;
        })
        .catch((error) => { });
    },
    getLogbook() {
      apiCall.get('api/glycemic_logbooks', this.patient_id)
        .then((response) => {
          this.updateDate = response.data.updated_at;
          this.glycemic_logbook_id = response.data.id;

          if (response.data.periods) {
            this.periodArray = response.data.periods;
            this.periodArray.forEach((period) => {
              this.$refs['period' + period.period_type_id][0].checkModal(true);

              this.updateActiveRecommendationInsulin()
            })
          }

          if (response.data.glycemic_objectives.length) {
            if (response.data.glycemic_objectives[response.data.glycemic_objectives.length - 1].objectives) {
              this.objectives = response.data.glycemic_objectives[response.data.glycemic_objectives.length - 1].objectives;
            }

            if (response.data.glycemic_objectives[response.data.glycemic_objectives.length - 1].insulin_recommandations) {
              this.dataEmpty = response.data.glycemic_objectives[response.data.glycemic_objectives.length - 1].insulin_recommandations.blood_sugar_empty_stomach;
              this.dataPost = response.data.glycemic_objectives[response.data.glycemic_objectives.length - 1].insulin_recommandations.blood_sugar_postprandial;

              this.updateBlock++
            }
          }

          if (response.data.glycemic_logbook_parameters !== null) {
            this.form = response.data.glycemic_logbook_parameters;
          }
        })
        .catch((error) => { });
    },
    openModal(period) {
      this.isOpen = true;
      this.modalData = period;
    },
    closeModal(period) {
      this.isOpen = false;
    },
    savePeriod(period) {
      if(period.form_inputs.blood_sugar.length === 0 && period.form_inputs.insulin.length === 0) {
        // A minimum of one checkbox is required. Insulin and blood sugar combined.
        showAlert('Avertissement', 'Période : vous devez sélectionner au moins 1 information pour valider la période.', "#dialogBox .dialog-body");
        return;
      }

      let parameters = {
        patient_id: this.patient_id,
        glycemic_logbook_id: this.glycemic_logbook_id,
        ...period
      }

      const existingPeriod = this.periodArray.find(({ period_type_id }) => period_type_id === period.period_type_id);

      if(existingPeriod === undefined) {
        this.periodArray.push(parameters)
      } else {
        existingPeriod.form_inputs = period.form_inputs
        // Cas de figure de la réactivation d'une période que l'on vient de supprimer (avant enregistrement final)
        delete existingPeriod.toDelete
      }

      this.$refs['period' + parameters.period_type_id][0].checkModal(true);
      this.updateActiveRecommendationInsulin()
      this.isOpen = false;
    },
    savePeriodApi() {     
      this.periodArray.map((item) => {
        if(item.id) {
          if(item.toDelete) {
            apiCall.delete('api/glycemic_logbooks', this.patient_id, 'periods', item.id)
              .then(() => {})
              .catch((error) => {
                showAlert('Avertissement', error.response.data.errors.form_inputs[0], "#dialogBox .dialog-body");
                this.loadingState = false
              });
            return;
          }

          item.glycemic_logbook_id = this.glycemic_logbook_id
          apiCall.updateSection('api/glycemic_logbooks', this.patient_id, 'periods', item.id, item)
            .then(() => {})
            .catch((error) => {
              showAlert('Avertissement', error.response.data.errors.form_inputs[0], "#dialogBox .dialog-body");
              this.loadingState = false
            });
          return;
        }

        apiCall.createSection('api/glycemic_logbooks', this.patient_id, 'periods', item)
        .then(() => {})
        .catch((error) => {
          showAlert('Avertissement', error.response.data.errors.form_inputs[0], "#dialogBox .dialog-body");
          this.loadingState = false
        });
      });

      this.saveObjectivesAndRecommendations();
    },
    deletePeriod(period) {
      if (this.periodArray.length) {
        let findPeriod = this.periodArray.find(({ period_type_id }) => period_type_id === period.id);
        findPeriod.toDelete = true
        this.updateActiveRecommendationInsulin()
      }
    },
    updateActiveRecommendationInsulin() {
      this.activeInsulinRecommandation = []
      this.periodArray.map((item) => {
          if(!item.toDelete) {
            this.activeInsulinRecommandation.push(...item.form_inputs.insulin);
          }
        });

      this.activeInsulinRecommandation = [...new Set(this.activeInsulinRecommandation)]
    },
    saveParameters() {
      let parameters = {
        hba1c: this.form.hba1c,
        weight: this.form.weight,
        height: this.form.height,
        glycemic_episode: this.form.glycemic_episode,
        periodicity: parseInt(this.form.periodicity)
      }

      apiCall.createSection('api/glycemic_logbooks', this.patient_id, 'glycemic_logbook_parameters', parameters)
        .then((response) => {
          this.savePeriodApi();
        })
        .catch((error) => {
          var errors = Object.values(error.response.data.errors);
          errors.forEach(function (listError, index) {
            listError.map((item) => {
              showAlert('Avertissement', item, "#dialogBox .dialog-body");
            });
          });
          this.loadingState = false
        });
    },
    saveObjectivesAndRecommendations() {
      let parameters = {
        objectives: this.objectives,
        insulin_recommandations: {
          blood_sugar_empty_stomach: this.dataEmpty === undefined ? {} : this.dataEmpty,
          blood_sugar_postprandial: this.dataPost === undefined ? {} : this.dataPost
        }
      }

      if(!this.activeInsulinRecommandation.includes('Lantus')) {
        delete parameters.insulin_recommandations.blood_sugar_empty_stomach
      }

      if(!this.activeInsulinRecommandation.includes('Apidra')) {
        delete parameters.insulin_recommandations.blood_sugar_postprandial
      }

      apiCall.createSection('api/glycemic_logbooks', this.patient_id, 'glycemic_objectives', parameters)
      .then(() => {
        window.location.href = "/professionnel/fiche_patient.php";
      })
      .catch((error) => {
        var errors = Object.values(error.response.data.errors);
        let listErrors = [];
        errors.forEach(function(error,index) {
          listErrors.push(error[0])
        });
        [...new Set(listErrors)].map((item)=>{
            showAlert('Avertissement', item, "#dialogBox .dialog-body");
        });
        }).finally(() => {
          this.loadingState = false
        });
    },
    cancel() {
      window.location.href = "/professionnel/fiche_patient.php";
    },
    submit() {
      this.loadingState = true
      if (this.periodArray.length != 0) {
        if(this.activeInsulinRecommandation.length === 0) {
          showAlert('Avertissement', 'Périodes : Veuillez sélectionner à minima 1 traitement', "#dialogBox .dialog-body");
          this.loadingState = false
          return;
        }

        this.saveParameters();
      } else {
        let message = "Veuillez sélectionner une période";
        showAlert('Avertissement', message, "#dialogBox .dialog-body");
        this.loadingState = false
      }

    }
  }
}
</script>

<style scoped>
.color_asterisk {
  color: red;
}
</style>
