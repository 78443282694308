var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-section h-100" }, [
    _c("h2", [_vm._v(_vm._s(_vm.$t("_50")))]),
    _vm._v(" "),
    _c("div", [
      _c("p", { staticClass: "font-weight-bold mt-4 mb-1" }, [
        _vm._v(_vm._s(_vm.$t("_51"))),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "nbSmsReceived" }, [
        _vm._v(_vm._s(_vm.mails.length)),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-4" }, [
      _c(
        "div",
        { staticClass: "col" },
        [
          _c(
            "table",
            {
              staticClass: "message_history table table--user table-striped",
              attrs: { id: "mail_history" },
            },
            [
              _c("thead", [
                _c("tr", [
                  _c("th", { attrs: { scope: "col" } }, [
                    _vm._v(_vm._s(_vm.$t("_52"))),
                  ]),
                  _vm._v(" "),
                  _c("th", { attrs: { scope: "col" } }, [
                    _vm._v(_vm._s(_vm.$t("_58"))),
                  ]),
                  _vm._v(" "),
                  _c("th", { attrs: { scope: "col" } }, [
                    _vm._v(_vm._s(_vm.$t("_54"))),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.pagesMail, function (mail) {
                  return _vm.loading
                    ? _c("tr", [
                        _c("td", [_vm._v(_vm._s(mail.title))]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.$moment(mail.sent_date).lang("fr").format("L")
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.$t("Sent")))]),
                      ])
                    : _vm._e()
                }),
                0
              ),
            ]
          ),
          _vm._v(" "),
          _vm.mails.length > 0
            ? _c("Pagination", {
                attrs: { contents: _vm.mails, "per-page": _vm.perPage },
                on: { pages: _vm.pages },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }