var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "input mt-0" },
    [
      _c("input", {
        staticClass: "input-body",
        attrs: {
          type: "radio",
          id: _vm.id,
          name: _vm.name,
          required: _vm.required,
          disabled: _vm.disabled,
        },
        domProps: { value: _vm.value, checked: _vm.model === _vm.value },
        on: { change: _vm.handleInput },
      }),
      _vm._v(" "),
      _c(
        "label",
        { staticClass: "input-label radio-label", attrs: { for: _vm.id } },
        [_vm._v("\n        " + _vm._s(_vm.label) + "\n    ")]
      ),
      _vm._v(" "),
      _vm.tooltip
        ? _c("TooltipSVG", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.tooltip,
                expression: "tooltip",
              },
            ],
            staticClass: "input-tooltip",
            attrs: { fill: "#525ca3" },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }