<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="size + 'em'" :height="size + 'em'" viewBox="0 0 20 20" fill="none">
        <path d="M19.1667 5L11.25 12.9167L7.08333 8.75L0.833328 15" stroke="currentColor" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14.1667 5H19.1667V10" stroke="currentColor" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
  name: "IconGrow",
  status: "prototype",
  release: "1.0.0",
  props: {
    /**
     * The size of the icon. Defaults to 24.
     * `1(default), 1.5, 2, 3, 4`
     */
    size: {
      type: [Number, String],
      default: '1'
    },
  },
}
</script>


