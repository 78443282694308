<template>
    <div class="parent-snack">
        <div class="row pl-3 pr-3 pb-3">
            <div v-if="periodData.form_inputs.blood_sugar.includes('2_hours_before')" class="col-sm-12 col-md-6 col-lg-6">
                <p>{{ $t('_glycemic_before_collation') }}</p>
                <div class="d-flex">
                    <Input name="snack_before" id="snack_before" label="" type="number" onwheel="return false;"
                        v-model.number="patient_inputs.blood_sugar.before" @input="updateData()" />
                    <p class="mt-auto mb-2 ml-3">mg/dL</p>
                </div>
            </div>
            <!-- <div v-if="periodData.form_inputs.insulin.includes('Apidra')" class="col-sm-12 col-md-6 col-lg-6 ">
                <div class="d-flex">
                    <p class="m-0 pt-5">{{ $t('_dose_apidra_collation') }}</p>
                    <div class="pl-1"><Input type="time" name="apidra_time" label="" class="p-0 m-0" :collapsed="false" v-model="patient_inputs.insulin.Apidra.when" @input="updateData()"/></div>
                </div>
                <div class="d-flex">
                    <Input name="helper" id="helper22" label="" type="number" v-model.number="patient_inputs.insulin.Apidra.dose" @input="updateData()"/>
                    <p class="mt-auto mb-3 ml-3">UI</p>
                </div>
            </div> -->
            <div v-if="periodData.form_inputs.blood_sugar.includes('2_hours_after')" class="col-sm-12 col-md-6 col-lg-6">
                <p>{{ $t('_glycemic_after_collation') }}</p>
                <div class="d-flex">
                    <Input name="helper" id="helper22" label="" type="number" onwheel="return false;"
                        v-model.number="patient_inputs.blood_sugar.after" @input="updateData()" />
                    <p class="mt-auto mb-2 ml-3">mg/dL</p>
                </div>
            </div>
        </div>
        <div v-if="periodData.form_inputs.insulin.includes('Apidra') || periodData.form_inputs.insulin.includes('Toujeo') || periodData.form_inputs.insulin.includes('Lantus')"
            class="row pl-3 pr-3">
            <div v-if="periodData.form_inputs.insulin.includes('Apidra')" class="col-sm-12 col-md-6 col-lg-6">
                <p class="font-weight-bold m-0">{{ $t('_Apidra') }}</p>
                <div class="d-flex m-0">
                    <p class="m-0 pt-1">{{ $t('_dose_apidra_afternoon') }}
                        <span class="d-inline-block text-align-center pl-2"><Input type="time" name="apidra_time" label=""
                                class="p-0 m-0" :collapsed="false" v-model="patient_inputs.insulin.Apidra.when"
                                @input="updateData()" /></span>
                    </p>
                </div>
                <div class="d-flex">
                    <Input name="sanck_apidra_dose" id="sanck_apidra_dose" label="" type="number" onwheel="return false;"
                        v-model.number="patient_inputs.insulin.Apidra.dose" @input="updateData()" />
                    <p class="mt-auto mb-3 ml-3">UI</p>
                </div>
            </div>
            <div v-if="periodData.form_inputs.insulin.includes('Lantus')" class="col-sm-12 col-md-6 col-lg-6">
                <p class="font-weight-bold m-0">{{ $t('_Lantus') }}</p>
                <div class="d-flex m-0">
                    <p class="m-0 pt-1">{{ $t('_dose_lantus_afternoon') }}
                        <span class="d-inline-block text-align-center pl-2"><Input type="time" name="lantus_time" label=""
                                class="p-0 m-0" :collapsed="false" v-model="patient_inputs.insulin.Lantus.when"
                                @input="updateData()" /></span>
                    </p>
                </div>
                <div class="d-flex">
                    <Input name="sanck_lantus_dose" id="sanck_lantus_dose" label="" type="number" onwheel="return false;"
                        v-model.number="patient_inputs.insulin.Lantus.dose" @input="updateData()" />
                    <p class="mt-auto mb-3 ml-3">UI</p>
                </div>
            </div>
            <div v-if="periodData.form_inputs.insulin.includes('Toujeo')" class="col-sm-12 col-md-6 col-lg-6">
                <p class="font-weight-bold m-0">{{ $t('_Toujeo') }}</p>
                <div class="d-flex m-0">
                    <p class="m-0 pt-1">{{ $t('_dose_toujeo_afternoon') }}
                        <span class="d-inline-block text-align-center pl-2"><Input type="time" name="apidra_time" label=""
                                class="p-0 m-0" :collapsed="false" v-model="patient_inputs.insulin.Toujeo.when"
                                @input="updateData()" /></span>
                    </p>
                </div>
                <div class="d-flex">
                    <Input name="sanck_toujeo_dose" id="sanck_toujeo_dose" label="" type="number" onwheel="return false;"
                        v-model.number="patient_inputs.insulin.Toujeo.dose" @input="updateData()" />
                    <p class="mt-auto mb-3 ml-3">UI</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "Snack",
    props: {
        period: {
            type: Object
        }
    },
    data() {
        return {
            patient_inputs: {
                insulin: {
                    Apidra: {
                        when: "",
                        dose: null
                    },
                    Lantus: {
                        when: "",
                        dose: null
                    },
                    Toujeo: {
                        when: "",
                        dose: null
                    }
                },
                blood_sugar: {
                    after: null,
                    before: null
                }
            },
            periodData: this.period,
        }
    },
    watch: {
        period(newVal, oldVal) {
            this.getPatientInputData();
        }
    },
    mounted() {
        this.getPatientInputData();
    },
    methods: {
        getPatientInputData() {
            if (this.periodData.period_patient_inputs.length != 0) {

                let obj2 = this.periodData.period_patient_inputs[0].patient_inputs;
                let obj1 = this.patient_inputs;
                for (let key1 in obj1) {
                    if (typeof obj2[key1] === 'object' && obj2[key1] !== null) {
                        for (let key2 in obj2[key1]) {
                            if (key2 == 'on_an_empty_stomach') {
                                obj1[key1]['before'] = obj2[key1][key2];
                            } else if (key2 == '2_hours_after') {
                                obj1[key1]['after'] = obj2[key1][key2];
                            } else if (key2 == '2_hours_before') {
                                obj1[key1]['before'] = obj2[key1][key2];
                            } else if (key2 == 'yes') {
                                obj1[key1]['before'] = obj2[key1][key2];
                            } else {
                                obj1[key1][key2] = obj2[key1][key2];
                            }
                        }
                    }
                }
                this.patient_inputs = obj1;
            } else {
                this.patient_inputs = {
                    insulin: {
                        Apidra: {
                            when: "",
                            dose: null
                        },
                        Lantus: {
                            when: "",
                            dose: null
                        },
                        Toujeo: {
                            when: "",
                            dose: null
                        }
                    },
                    blood_sugar: {
                        after: null,
                        before: null
                    }
                }
            }
        },
        updateData() {
            let idObject = {
                id: this.periodData.id,
                period_type_id: this.periodData.period_type_id,
                period_patient_inputs_id: this.periodData.period_patient_inputs.length > 0 ? this.periodData.period_patient_inputs[0].id : null
            }
            this.patient_inputs = {
                ...this.patient_inputs,
                idObject
            }

            this.$emit('updateData', this.patient_inputs)
        }
    }
}
</script>

<style>
.parent-snack input {
    padding-top: 0px !important;
}
</style>