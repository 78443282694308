var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "parent-lunch" }, [
    _vm.periodData.form_inputs.blood_sugar.includes("2_hours_before") ||
    _vm.periodData.form_inputs.blood_sugar.includes("2_hours_after")
      ? _c("div", { staticClass: "row pl-3 pr-3 pb-3" }, [
          _vm.periodData.form_inputs.blood_sugar.includes("2_hours_before")
            ? _c("div", { staticClass: "col-sm-12 col-md-6 col-lg-6" }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("_glycemic_before_lunch")))]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c("Input", {
                      attrs: {
                        name: "before_lunch",
                        id: "before_lunch",
                        type: "number",
                        onwheel: "return false;",
                        label: "",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.updateData()
                        },
                      },
                      model: {
                        value: _vm.patient_inputs.blood_sugar.before,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.patient_inputs.blood_sugar,
                            "before",
                            _vm._n($$v)
                          )
                        },
                        expression: "patient_inputs.blood_sugar.before",
                      },
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "mt-auto mb-2 ml-3" }, [
                      _vm._v("mg/dL"),
                    ]),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.periodData.form_inputs.blood_sugar.includes("2_hours_after")
            ? _c("div", { staticClass: "col-sm-12 col-md-6 col-lg-6" }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("_glycemic_2h_after_lunch")))]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c("Input", {
                      attrs: {
                        name: "after_lunch",
                        id: "after_lunch",
                        label: "",
                        type: "number",
                        onwheel: "return false;",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.updateData()
                        },
                      },
                      model: {
                        value: _vm.patient_inputs.blood_sugar.after,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.patient_inputs.blood_sugar,
                            "after",
                            _vm._n($$v)
                          )
                        },
                        expression: "patient_inputs.blood_sugar.after",
                      },
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "mt-auto mb-2 ml-3" }, [
                      _vm._v("mg/dL"),
                    ]),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.periodData.form_inputs.insulin.includes("Apidra") ||
    _vm.periodData.form_inputs.insulin.includes("Toujeo") ||
    _vm.periodData.form_inputs.insulin.includes("Lantus")
      ? _c("div", { staticClass: "row pl-3 pr-3" }, [
          _vm.periodData.form_inputs.insulin.includes("Apidra")
            ? _c("div", { staticClass: "col-sm-12 col-md-6 col-lg-6" }, [
                _c("p", { staticClass: "font-weight-bold m-0" }, [
                  _vm._v(_vm._s(_vm.$t("_Apidra"))),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex m-0" }, [
                  _c("p", { staticClass: "m-0 pt-1" }, [
                    _vm._v(
                      _vm._s(_vm.$t("_dose_apidra_lunch")) +
                        "\n                    "
                    ),
                    _c(
                      "span",
                      { staticClass: "d-inline-block text-align-center pl-2" },
                      [
                        _c("Input", {
                          staticClass: "p-0 m-0",
                          attrs: {
                            type: "time",
                            name: "apidra_time",
                            label: "",
                            collapsed: false,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.updateData()
                            },
                          },
                          model: {
                            value: _vm.patient_inputs.insulin.Apidra.when,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.patient_inputs.insulin.Apidra,
                                "when",
                                $$v
                              )
                            },
                            expression: "patient_inputs.insulin.Apidra.when",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c("Input", {
                      attrs: {
                        name: "lunch_apidra_dose",
                        id: "lunch_apidra_dose",
                        label: "",
                        type: "number",
                        onwheel: "return false;",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.updateData()
                        },
                      },
                      model: {
                        value: _vm.patient_inputs.insulin.Apidra.dose,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.patient_inputs.insulin.Apidra,
                            "dose",
                            _vm._n($$v)
                          )
                        },
                        expression: "patient_inputs.insulin.Apidra.dose",
                      },
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "mt-auto mb-3 ml-3" }, [
                      _vm._v("UI"),
                    ]),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.periodData.form_inputs.insulin.includes("Lantus")
            ? _c("div", { staticClass: "col-sm-12 col-md-6 col-lg-6" }, [
                _c("p", { staticClass: "font-weight-bold m-0" }, [
                  _vm._v(_vm._s(_vm.$t("_Lantus"))),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex m-0" }, [
                  _c("p", { staticClass: "m-0 pt-1" }, [
                    _vm._v(
                      _vm._s(_vm.$t("_dose_lantus_lunch")) +
                        "\n                    "
                    ),
                    _c(
                      "span",
                      { staticClass: "d-inline-block text-align-center pl-2" },
                      [
                        _c("Input", {
                          staticClass: "p-0 m-0",
                          attrs: {
                            type: "time",
                            name: "lantus_time",
                            label: "",
                            collapsed: false,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.updateData()
                            },
                          },
                          model: {
                            value: _vm.patient_inputs.insulin.Lantus.when,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.patient_inputs.insulin.Lantus,
                                "when",
                                $$v
                              )
                            },
                            expression: "patient_inputs.insulin.Lantus.when",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c("Input", {
                      attrs: {
                        name: "lunch_lantus_dose",
                        id: "lunch_lantus_dose",
                        label: "",
                        type: "number",
                        onwheel: "return false;",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.updateData()
                        },
                      },
                      model: {
                        value: _vm.patient_inputs.insulin.Lantus.dose,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.patient_inputs.insulin.Lantus,
                            "dose",
                            _vm._n($$v)
                          )
                        },
                        expression: "patient_inputs.insulin.Lantus.dose",
                      },
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "mt-auto mb-3 ml-3" }, [
                      _vm._v("UI"),
                    ]),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.periodData.form_inputs.insulin.includes("Toujeo")
            ? _c("div", { staticClass: "col-sm-12 col-md-6 col-lg-6" }, [
                _c("p", { staticClass: "font-weight-bold m-0" }, [
                  _vm._v(_vm._s(_vm.$t("_Toujeo"))),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex m-0" }, [
                  _c("p", { staticClass: "m-0 pt-1" }, [
                    _vm._v(
                      _vm._s(_vm.$t("_dose_toujeo_lunch")) +
                        "\n                    "
                    ),
                    _c(
                      "span",
                      { staticClass: "d-inline-block text-align-center pl-2" },
                      [
                        _c("Input", {
                          staticClass: "p-0 m-0",
                          attrs: {
                            type: "time",
                            name: "apidra_time",
                            label: "",
                            collapsed: false,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.updateData()
                            },
                          },
                          model: {
                            value: _vm.patient_inputs.insulin.Toujeo.when,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.patient_inputs.insulin.Toujeo,
                                "when",
                                $$v
                              )
                            },
                            expression: "patient_inputs.insulin.Toujeo.when",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c("Input", {
                      attrs: {
                        name: "lunch_toujeo_dose",
                        id: "lunch_toujeo_dose",
                        label: "",
                        type: "number",
                        onwheel: "return false;",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.updateData()
                        },
                      },
                      model: {
                        value: _vm.patient_inputs.insulin.Toujeo.dose,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.patient_inputs.insulin.Toujeo,
                            "dose",
                            _vm._n($$v)
                          )
                        },
                        expression: "patient_inputs.insulin.Toujeo.dose",
                      },
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "mt-auto mb-3 ml-3" }, [
                      _vm._v("UI"),
                    ]),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }