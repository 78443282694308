const toFormData = (data, form, parent) => {
    if (
        data &&
        typeof data === 'object' &&
        !(data instanceof Date) &&
        !(data instanceof File) &&
        !(data instanceof Blob)
    ) {
        Object.keys(data).forEach(key =>
            toFormData(data[key], form, parent ? `${parent}[${key}]` : key)
        );
    } else {
        form.append(parent, data || '');
    }
};

export default data => {
    const form = new FormData();

    toFormData(data, form);

    return form;
};
