<template>
  <component
    :is="wrapper" :class="['custom-range-wrapper']">
    <div class="custom_range" style="position:relative; margin:auto; width:90%;">
      <output v-if="name" name="custom-range-output" :id="'output_' + id" class="custom-range-output" style="position:absolute; min-width:100px; text-align: center;"><div v-if="rangeValue != null">{{ rangeValue }}</div><div v-else>[]</div></output>
      <div class="left-range" :id="'left_range_' + id"></div>
      <input ref="prev"
             v-if="id"
             :type="type"
             :class="['custom-range']"
             :id="id"
             :min="minValue"
             :max="maxValue"
             :step="step"
             :value="val"
             :name="name"
             v-model="rangeValue"
             :disabled="disabled"
             @change="onChange($event.target.value)">
      <div class="d-flex justify-content-between align-items-center">
        <label :for="id + '_min_value'" class="text-left">{{ minLabel }} <span :class="{'d-none' : minLabel}">{{ minValue }}</span></label>
        <label :for="id + '_max_value'" class="text-right">{{ maxLabel }} <span :class="{'d-none' : maxLabel}">{{ maxValue }}</span></label>
      </div>
    </div>
  </component>
</template>

<script>
export default {
  name: "Custom",

  props: {
    /**
     * Unique ProgressBar identifier.
     */
    id: {
      type: String,
      default: null,
    },

    /**
     * Name identifier for form
     */
    name: {
      type: String,
      default: 'range',
    },

    /**
     * The type of the form input control.
     * `range`
     */

    type: {
      type: String,
      default: "range"
    },

    /**
     * Minimum value on the range.
     */
    minValue: {
      type:  [Number, String],
      default: 0,
    },

    /**
     * Maximum value on the range.
     */
    maxValue: {
      type:  [Number, String],
      default: 10,
    },

    /**
     * Custom label for the minimum value on the range.
     */
    minLabel: {
      type:  [Number, String],
      default: null,
    },

    /**
     * Custom label for the maximum value on the range.
     */
    maxLabel: {
      type:  [Number, String],
      default: null,
    },

    /**
     * By default, range inputs “snap” to integer values.
     * To change this, you can specify a step value. Example, we double the number of steps by using  step="0.5".
     */
    step: {
      type: Number,
      default: null,
    },

    /**
     * Whether the form input field is disabled or not.
     * `true, false`
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * The html element name used for the wrapper.
     * `div, section`
     */
    wrapper: {
      type: String,
      default: "div",
      validator: value => {
        return value.match(/(div|section)/)
      }
    },

    val: {
      type: [Number, String],
      default: 0
    }
  },
  created() {
    window.addEventListener("resize", this.rangeChange)
  },
  destroyed() {
    window.addEventListener("resize", this.rangeChange)
  },
  data () {
    return {
      rangeValue: this.val,
    }
  },

  mounted() {
    this.rangeChange();
  },

  created() {
    window.addEventListener("resize", this.rangeChange)
  },

  destroyed() {
    window.addEventListener("resize", this.rangeChange)
  },

  methods: {
    onChange: function(val) {
      this.$emit('input', val)
      this.rangeChange()
    },
    rangeChange: function() {

      let leftRange = document.querySelector('#left_range_' + this.id);
      var myRange = document.querySelector('#' + this.id);
      var myValue = document.querySelector('#output_' + this.id);
      var thumbWidth = 18;

      if (myRange != null && myValue != null) {

        var off = (myRange.clientWidth - thumbWidth) / (parseInt(myRange.max) - parseInt(myRange.min));
        var px = ((myRange.valueAsNumber - parseInt(myRange.min)) * off) - (myValue.clientWidth / 2) + (thumbWidth / 2);

        var off = (myRange.clientWidth - thumbWidth) / (parseInt(myRange.max) - parseInt(myRange.min));
        var px = ((myRange.valueAsNumber - parseInt(myRange.min)) * off) - (myValue.clientWidth / 2) + (thumbWidth / 2);

        myValue.style.left = px + 'px';
        myValue.style.top = -myRange.offsetHeight - 5 + 'px';
        myValue.innerHTML = myValue.textContent != '[]' ? myRange.value : '[]';

        let leftRangeWidth = myValue.offsetLeft + (myValue.offsetWidth / 2);
        leftRange.style.width = leftRangeWidth + 'px';

        myRange.oninput = function() {
          let px = ((myRange.valueAsNumber - parseInt(myRange.min)) * off) - (myValue.clientWidth / 2) + (thumbWidth / 2);
          myValue.innerHTML = myRange.value;
          myValue.style.left = px + 'px';
        };
      }
    }
  },
}

</script>

<style scoped>
.custom_range {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.custom-range::-webkit-progress-value {
  background-color: #525CA3 !important;
}

.custom-range::-webkit-slider-thumb {
  background-color: #525CA3 !important;
}
/* Firefox */
.custom-range::-moz-range-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #525CA3 !important;
}

.custom-range::-moz-range-progress {
  height: 0;
  background: #525CA3 !important;
}

/* IE */
.custom-range::-ms-fill-lower {
  background-color: #fff;
  border-radius: 10px;
}

.custom-range::-ms-fill-upper {
  background-color: #525CA3;
  border-radius: 10px;
}

.custom-range::-ms-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #525CA3 !important;
}

.left-range {
  display: block;
  width: 0;
  height: 8px;
  background-color: #525CA3;
  position: absolute;
  margin-top: 7px;
  border-radius: 5px 0 0 5px;
}
</style>
