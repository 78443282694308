<template>
  <div>
    <select name="" id="" @change="change" :style="{width: width}">
      <option v-for="(data, index) in datas" :key="index"
          :value="data.value"
          :disabled="data.disabled" 
          :selected="selectedOption(data)">
          {{ data.text }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "SelectGlobal",

  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    datas: {
      type: Array,
      required: true,
    }, 
    width: {
      default: "50%"
    }
  }, 
  methods: {
    selectedOption(option) {
      if (this.value) {
        return option.value === this.value;
      }
      return false;
    },
    change(e) {
      this.$emit("input", e.target.value);
    },
  }
}
</script>

<style scoped>
select {
  border: none;
  color: gray;
  border-bottom: 1px solid lightgray;
}
</style>
