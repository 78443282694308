// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.input-width[data-v-110b33b0] {
    width: 2rem;
}
`, "",{"version":3,"sources":["webpack://./project/private/Resources/js/components/inputs/CustomInput.vue"],"names":[],"mappings":";AAkDA;IACA,WAAA;AACA","sourcesContent":["<template>\n    <div class=\"d-flex align-items-center justify-content-between\">\n        <p class=\"font-weight-bold m-0\">{{ textBefore }}</p>\n        <Input :name=\"name\" :id=\"id\" label=\"\" class=\"input-width p-0\" @input=\"handleInput\" :value=\"value\" :readonly=\"readonly\" :bold=\"bold\"/>\n        <p class=\"m-0\">{{ textAfter }}</p>\n    </div>\n</template>\n\n<script>\n\nexport default {\nname: \"CustomInput\", \nprops: {\n    textBefore: {\n        type: String\n    }, \n    textAfter: {\n        type: String\n    }, \n    name: {\n        type: String\n    }, \n    id: {\n        type: String\n    }, \n    value: {\n        type: [String, Number],\n    }, \n    modalObjectif: {\n        type: Boolean, \n        default: false\n    }, \n    readonly: {\n        type: Boolean, \n        default: false\n    }, \n    bold: {\n        type: Boolean, \n        default: false\n    }\n}, \nmethods: {\n    handleInput (value) {\n        this.$emit('input', value)\n    }\n}\n}\n</script>\n\n<style scoped>\n.input-width {\n    width: 2rem;\n}\n</style>"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
