<template>
    <div class="pagination">
      <div class="changepage changepagevue" v-bind:class="{ 'hidden': page == 1 }" @click="page = 1">1</div>
      <div class="changepage changepagevue" v-for="pageNumber in pages.slice(page-1, page+5)" @click="changePage(pageNumber)"
           v-bind:class="{ 'active': pageNumber == page }">{{ pageNumber }}
      </div>
    </div>
</template>

<script>
export default {
  props: {
    contents: {
      type: Array,
      required: true,
    },

    perPage: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      page: 1,
      pages: [],
    }
  },

  created() {
    this.setPages();
    this.sendValues();
  },

  computed: {
    displayContent() {
      this.setPages();
      this.sendValues();
      return this.paginate(this.contents);
    }
  },

  methods: {
    setPages() {
      this.pages = [];
      let numberOfPages = Math.ceil(this.contents.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },

    paginate(content) {
      let page = this.page;
      let perPage = this.perPage;
      let from = (page * perPage) - perPage;
      let to = (page * perPage);

      return content.slice(from, to);
    },

    sendValues() {
      this.$emit('pages', this.paginate(this.contents));
    },

    changePage(value) {
      this.page = value;

      this.$emit('pages', this.paginate(this.contents));
    }
  },
}

</script>

<style scoped>
  .changepagevue {
    text-align: center;
  }
</style>
