var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: { title: _vm.$t("_confirmation"), isOpen: _vm.isOpen },
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      typeof _vm.solutions !== "undefined" && _vm.solutions.includes(6)
        ? _c("div", [
            _c("h3", { staticClass: "pt-0" }, [_vm._v(_vm._s(_vm.$t("_224")))]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("mdc_registration")) +
                  "\n        "
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      typeof _vm.solutions !== "undefined" && _vm.solutions.includes(4)
        ? _c("div", [
            _c("h3", { staticClass: "pt-0" }, [_vm._v(_vm._s(_vm.$t("_95")))]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("cgm_registration")) +
                  "\n        "
              ),
            ]),
          ])
        : _c("p", [
            _vm._v(
              "\n        " + _vm._s(_vm.$t("registration_over")) + "\n    "
            ),
          ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("\n        " + _vm._s(_vm.$t("confirm")) + "\n    ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }