var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-section h-100" }, [
    _c(
      "div",
      {
        staticClass: "change-mode",
        attrs: { id: "modifierFicheMedical" },
        on: {
          click: function ($event) {
            return _vm.redirectFormMedical()
          },
        },
      },
      [
        _c("div", { attrs: { id: "iconModif" } }, [
          _c("i", { staticClass: "fas fa-pencil-alt" }),
          _vm._v(" "),
          _c("span", { staticClass: "change-tooltip" }, [
            _vm._v(_vm._s(_vm.$t("_74"))),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("h2", [_vm._v(_vm._s(_vm.$t("_75")))]),
    _vm._v(" "),
    _c("div", { staticClass: "h-100" }, [
      _vm.user.diabetes.diabetes_active && !_vm.isHtaCard
        ? _c("div", { staticClass: "row mt-4" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _c("h3", { staticClass: "title_fiche_patient" }, [
                _vm._v(_vm._s(_vm.$t("_76"))),
              ]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.translateDiabetesType))]),
            ]),
            _vm._v(" "),
            _vm.user.diabetes.diabetes_active && !_vm.isHtaCard
              ? _c("div", { staticClass: "col-md-6" }, [
                  _c("h3", { staticClass: "title_fiche_patient" }, [
                    _vm._v(_vm._s(_vm.$t("_154"))),
                  ]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.diabetesTreatmentJoined))]),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.user.hypertension.hypertension_active && !_vm.isDiabCard
        ? _c("div", { staticClass: "row mt-4" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _c("h3", { staticClass: "title_fiche_patient" }, [
                _vm._v(_vm._s(_vm.$t("_76"))),
              ]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.$t("_149")))]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6" }, [
              _c("h3", { staticClass: "title_fiche_patient" }, [
                _vm._v(_vm._s(_vm.$t("_hypertension_treatments") + " :")),
              ]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.hypertensionTreatmentFromList))]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.user.diabetes.diabetes_active && !_vm.isHtaCard
        ? _c("div", { staticClass: "row mt-4" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("h3", { staticClass: "title_fiche_patient" }, [
                _vm._v(_vm._s(_vm.$t("_77"))),
              ]),
              _vm._v(" "),
              _vm.user.diabetes.insulin_less_than_3_months == 1 &&
              !_vm.isHtaCard
                ? _c("span", [_vm._v(_vm._s(_vm.$t("_78")))])
                : _c("span", [_vm._v(_vm._s(_vm.$t("_79")))]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.user.hypertension.hypertension_active && !_vm.isDiabCard
        ? _c("div", { staticClass: "row mt-4" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _c("h3", { staticClass: "title_fiche_patient" }, [
                _vm._v(_vm._s(_vm.$t("_antihypertensive_less_than_6_months"))),
              ]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.HasHTaHypertensive))]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6" }, [
              _c("h3", { staticClass: "title_fiche_patient" }, [
                _vm._v(_vm._s(_vm.$t("_tabagisme"))),
              ]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.HasHTaSmoking))]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6 mt-4" }, [
              _c("h3", { staticClass: "title_fiche_patient" }, [
                _vm._v(_vm._s(_vm.$t("_p1"))),
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  "\n          Diab eCare / Diab eSupport : " +
                    _vm._s(_vm.hasDiabECare) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  "\n          HTA eCare : " +
                    _vm._s(_vm.hasHtaECare) +
                    "\n        "
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.context != "cro" && _vm.isLogbookActive && !_vm.isHtaCard
        ? _c("div", { staticClass: "row mt-4" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("h3", { staticClass: "title_fiche_patient" }, [
                _vm._v(_vm._s(_vm.$t("_80"))),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.context != "cro" && _vm.isLogbookActive && !_vm.isHtaCard
        ? _c("div", { staticClass: "row mt-2" }, [
            _c("div", { staticClass: "col-sm-12 col-md-6 col-md-6" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-primary",
                  on: {
                    click: function ($event) {
                      return _vm.goToLogbook()
                    },
                  },
                },
                [
                  _c("icon-book", { staticClass: "mr-2" }),
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.isCreated
                          ? _vm.$t("_edit_logbook")
                          : _vm.$t("_create_logbook")
                      ) +
                      "\n          "
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm.isCreated == true
              ? _c("div", { staticClass: "col-sm-12 col-md-6 col-md-6" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-primary",
                      on: {
                        click: function ($event) {
                          return _vm.goToFollowUp()
                        },
                      },
                    },
                    [
                      _c("icon-grow", { staticClass: "mr-2" }),
                      _vm._v(_vm._s(_vm.$t("_check_stats"))),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row mt-2" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-primary",
              on: {
                click: function ($event) {
                  return _vm.goToConsentFile()
                },
              },
            },
            [_vm._v("\n          " + _vm._s(_vm.$t("_101")) + "\n        ")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }