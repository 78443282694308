var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "input mb-3",
      class: {
        "input-colapsed": _vm.collapsed,
        "mt-6": _vm.customMargin,
        "mt-0": !_vm.customMargin,
      },
    },
    [
      _c(
        "label",
        {
          staticClass: "input-label",
          class: { asteriskRequired: _vm.required },
          attrs: { for: _vm.id || _vm.name },
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.label))]),
          _vm._v(" "),
          _vm.tooltip
            ? _c("TooltipSVG", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.tooltip,
                    expression: "tooltip",
                  },
                ],
                staticClass: "input-tooltip",
                attrs: { fill: "#525ca3" },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("input", {
        staticClass: "input-body",
        class: _vm.bold ? "font-weight-bold" : "",
        attrs: {
          id: _vm.id || _vm.name,
          type: _vm.type,
          name: _vm.name,
          required: _vm.required,
          readonly: _vm.readonly,
          disabled: _vm.disabled,
          autocomplete: _vm.autocomplete,
          min: _vm.min,
          max: _vm.max,
          bold: _vm.bold,
        },
        domProps: { value: _vm.value },
        on: {
          input: _vm.handleInput,
          focus: (event) => _vm.$emit("focus", event),
          blur: (event) => _vm.$emit("blur", event),
          keydown: (event) => _vm.$emit("keydown", event),
          wheel: function ($event) {
            $event.preventDefault()
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }