var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row justify-content-start" }, [
      _c("div", { staticClass: "col-sm-12 col-md-5 col-lg-5" }, [
        _c(
          "div",
          { staticClass: "row justify-content-end" },
          [_vm._t("firstColumn")],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "col-sm-12 col-md-6 col-lg-6 text-white rounded mt-3 h-50",
          style: { backgroundColor: _vm.background },
        },
        [_c("div", { staticClass: "row" }, [_vm._t("secondColumn")], 2)]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }