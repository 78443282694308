<template>
  <div class="page-section">
    <div v-if="formOpen != true" class="change-mode" id="modifIdentityInfo">
      <div v-on:click="openForm()">
        <i class="fas fa-pencil-alt"></i>
        <span class="change-tooltip">{{ $t('_33') }}</span>
      </div>
    </div>
    <h2>{{ $t('_34') }}</h2>
    <div>
      <form
        enctype="multipart/form-data"
        class="bg-white rounded-lg"
        method="post"
        action="/ws/update_patient.php"
        @submit.prevent="submit"
      >
        <div v-if="user.events.length > 0" class="row border-bottom">
          <div class="col-md-12">
            <h3 class="title_fiche_patient">{{ $t('_35') }}</h3>
            <InputGroup required>
              <Radio
                v-model="form.formations.rafiq_essiha.validate"
                value="oui"
                name="formation_rafiq_essiha"
                :disabled="formOpen === false"
                :readonly="formOpen === false"
                id="formation_rafiq_essiha"
                :label="$t('_134')"
              />

              <Radio
                v-model="form.formations.rafiq_essiha.validate"
                value="non"
                name="formation_rafiq_essiha"
                :disabled="formOpen === false"
                :readonly="formOpen === false"
                id="formation_rafiq_essiha2"
                :label="$t('_135')"
              />
            </InputGroup>
          </div>
          <div class="col-md-12">
            <table id="formation_rafiq_essiha_recu" class="table table--user table-striped">
              <thead>
              <tr>
                <th scope="col">{{ $t('_36') }}</th>
                <th scope="col">{{ $t('_37') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(rafiq_essiha_recu, index) in form.formations.rafiq_essiha.recu" :key="index">
                <td v-if="rafiq_essiha_recu.type == 1">{{ $t('_38') }}</td>
                <td v-if="rafiq_essiha_recu.type == 2">{{ $t('_39') }}</td>
                <td>{{ $moment(rafiq_essiha_recu.date).locale("fr").format('L') }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="user.solutions.mdc" class="row border-bottom mt-3">
          <div class="col-md-12">
            <h3 class="title_fiche_patient">{{ $t('_40') }}</h3>
            <InputGroup required>
              <Radio
                v-model="form.formations.mdc.validate"
                value="oui"
                name="formation_mdc"
                :disabled="formOpen === false"
                :readonly="formOpen === false"
                id="formation_mdc"
                :label="$t('_136')"
              />

              <Radio
                v-model="form.formations.mdc.validate"
                value="non"
                name="formation_mdc"
                :disabled="formOpen === false"
                :readonly="formOpen === false"
                id="formation_mdc2"
                :label="$t('_137')"
              />
            </InputGroup>
          </div>
          <div class="col-md-12">
            <table id="formation_mdc_recu" class="table table--user">
              <thead>
              <tr>
                <th scope="col">{{ $t('_41') }}</th>
                <th scope="col">{{ $t('_42') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td><a href="https://dz.mydosecoach.com/" target="_blank" class="attended btn btn-small"
                       style="text-transform: none" title="MyDoseCoache">{{ $t('_43') }}</a></td>
                <td v-if="form.formations.mdc.date">{{ $moment(form.formations.mdc.date).locale("fr").format('L') }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="user.solutions.cgm" class="row mt-3 border-bottom">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">
                <h3 class="title_fiche_patient">{{ $t('_227') }}</h3>
                <InputGroup required>
                  <Radio
                    v-model="form.formations.cgm.validate"
                    value="oui"
                    name="formation_cgm"
                    :disabled="formOpen === false"
                    :readonly="formOpen === false"
                    id="formation_cgm"
                    :label="$t('_138')"
                  />

                  <Radio
                    v-model="form.formations.cgm.validate"
                    value="non"
                    name="formation_cgm"
                    :disabled="formOpen === false"
                    :readonly="formOpen === false"
                    id="formation_cgm2"
                    :label="$t('_139')"
                  />
                </InputGroup>
              </div>
              <div class="col-md-6">
                <div scope="col">
                  <h3 class="title_fiche_patient">{{ $t('_45') }}</h3>
                </div>
                <div v-if="temp_date_for_cgm">
                  <Input
                    v-model="temp_date_for_cgm"
                    type="date"
                    :disabled="formOpen === false"
                    :readonly="formOpen === false"
                    name="education_date"
                    min="1900-01-01"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="user.cg" class="row mt-3 border-bottom">
          <div class="col-md-12">
            <h3 class="title_fiche_patient">{{ $t('_40') }} Carnet glycémique</h3>
            <InputGroup required>
              <Radio
                v-model="form.formations.cg.validate"
                value="oui"
                name="formation_cg"
                :disabled="formOpen === false"
                :readonly="formOpen === false"
                id="formation_cg"
                :label="$t('_136')"
              />

              <Radio
                v-model="form.formations.cg.validate"
                value="non"
                name="formation_cg"
                :disabled="formOpen === false"
                :readonly="formOpen === false"
                id="formation_cg2"
                :label="$t('_137')"
              />
            </InputGroup>
          </div>
          <div class="col-md-12">
            <table id="formation_carnet_glycemique_recu" class="table table--user">
              <thead>
              <tr>
                <th scope="col">{{ $t('_41') }}</th>
                <th scope="col">{{ $t('_42') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td><a href="https://dz.mydosecoach.com/" target="_blank" class="attended btn btn-small"
                       style="text-transform: none" title="MyDoseCoache">{{ $t('_43') }}</a></td>
                <td v-if="form.formations.cg.date">{{ $moment(form.formations.cg.date).lang("fr").format('L') }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="user.solutions.cgm && form.formations.cgm.validate == 'oui'" class="row mt-3">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">
                <h3 class="title_fiche_patient asteriskRequired">{{ $t('_batch_number') }}</h3>
                <Input
                  class="mb-4"
                  v-model="form.nb_lot"
                  name="education_number"
                  :disabled="formOpen === false"
                  :readonly="formOpen === false"
                />
              </div>
              <div class="col-md-6">
                <h3 class="title_fiche_patient">{{ $t('_sensor_expiry_date') }}</h3>
                <Input
                  v-model="form.kit_date"
                  type="date"
                  :disabled="formOpen === false"
                  :readonly="formOpen === false"
                  name="education_date"
                  min="1900-01-01"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                  <InputGroup
                    required
                  >
                    <Checkbox
                      v-model="form.has_received_kit"
                      name="has_received_kit"
                      id="has_received_kit"
                      label="Je certifie que le patient a reçu le kit complet (transmetteur et sensors) et a bien été formé"
                      :disabled="formOpen === false"
                    />
                  </InputGroup>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <fieldset v-if="formOpen == true" :disabled="isSumbitDisabled">
            <Submit
              class="text-center"
              label="Enregistrer les modifications"
            />
          </fieldset>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import InputGroup from "../inputs/InputGroup";
import Input from "../inputs/Input";
import Radio from "../inputs/Radio";
import Autocomplete from "../inputs/Autocomplete";
import Checkbox from "../inputs/Checkbox";
import FileUpload from "../inputs/FileUpload";
import Submit from "../inputs/Submit";
import {toFormData} from "../../helpers";

export default {
  watch: {},
  components: {
    InputGroup,
    Input,
    Radio,
    Autocomplete,
    Checkbox,
    FileUpload,
    Submit,
  },

  name: "FormationsPatient",

  props: {
    context: {
      type: String,
      required: true,
    },

    user: {
      type: Object,
      required: true,
    },

    uuid: {
      type: String,
      required: true,
    },

    editModeActivated: {
      type: Function,
    },
  },

  data() {
    return {
      loading: false,
      formOpen: false,
      temp_date_for_cgm: false,
      form: {
        form: 'form_edit_trainings',
        patient_id: this.user.patient_id,
        has_received_kit: null,
        kit_date: false,
        nb_lot: null,
        formations: {
          rafiq_essiha: {
            id: null,
            validate: 'non',
            date: false,
          },
          mdc: {
            id: null,
            validate: 'non',
            date: false,
          },
          cgm: {
            id: null,
            validate: 'non',
            date: false,
          },
          // cg: {
          //   id: null,
          //   validate: 'non',
          //   date: false,
          //   recu: ''
          // },
        },



      },
    }
  },

  mounted() {
    if(this.user.nb_lot !== "") {
      this.form.nb_lot = this.user.nb_lot;
      this.$nextTick(() => {
        this.form.has_received_kit = this.user.has_received_kit == 1 ? true : false;
      });
      if(this.user.kit_date != "") {
        const dateObj = new Date(this.user.kit_date);
        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const day = String(dateObj.getDate()).padStart(2, '0');
        this.form.kit_date = `${year}-${month}-${day}`;
      }
    }

    if (this.user.events.length > 0) {
      this.form.formations.rafiq_essiha.validate = 'oui'
      this.form.formations.rafiq_essiha.recu = this.user.events
    }

    if (this.user.solutions.mdc) {
      this.form.formations.mdc.id = this.user.solutions.mdc_id
      if (this.user.solutions.mdc_date && this.user.solutions.mdc) {
        this.form.formations.mdc.validate = 'oui'
      }
      this.form.formations.mdc.date = this.user.solutions.mdc_date
    }

    if (this.user.solutions.cgm) {
      this.form.formations.cgm.id = this.user.solutions.cgm_id
      if (this.user.solutions.cgm_date && this.user.solutions.cgm) {
        this.form.formations.cgm.validate = 'oui'
      }
      this.form.formations.cgm.date = this.user.solutions.cgm_date;
      if(this.user.solutions.cgm_date) {
        this.temp_date_for_cgm = this.$moment(this.user.solutions.cgm_date).locale("fr").format('Y-MM-DD');
      }
    }
  },
  watch: {
    'form.formations.cgm.validate': {
      handler: function(val, oldVal) {
        if (val == 'non') {
          this.form.formations.cgm.date = null,
          this.temp_date_for_cgm = null,
          this.form.nb_lot = null,
          this.form.has_received_kit = false,
          this.form.kit_date = null
        }
        if (val == 'oui') {
          if(this.formOpen != false) {
            this.temp_date_for_cgm = this.$moment().locale("fr").format('Y-MM-DD');
          }
        }
      },
      deep: true
    }
  },

  computed: {
    isSumbitDisabled() {
      return (this.formOpen === false || 
        ( this.form.formations.cgm.validate == 'oui'
          && (this.form.has_received_kit != true
          || this.form.nb_lot == null || this.form.nb_lot == '')
        )
      )
    },
  },

  methods: {
    openForm: function () {
      this.formOpen = true
      this.editModeActivated()
    },

    submit(event) {
      this.loading = true;
      if (this.form.formations.cgm.validate == 'oui') {
        if(this.temp_date_for_cgm) {
          this.form.formations.cgm.date = this.$moment(this.temp_date_for_cgm).locale("fr").format('Y-M-D HH:MM:SS');
        }
      }
      this.$axios.post(event.target.action, toFormData(this.form))
        .then(response => {
          let kit_checkbox = this.form.has_received_kit;
          this.loading = false;
          this.formOpen = false;
          this.editModeActivated()
          this.$nextTick(() => {
            this.form.has_received_kit = kit_checkbox;
          });
        })
        .catch(e => {
          if( e.response.status == 400 ) {
            showAlert('Avertissement', e.response.statusText, "#dialogBox .dialog-body");
          }
          this.loading = false;
          this.formOpen = false;
          this.editModeActivated()
        });
    },
  }
}
</script>

<style scoped>
.title_fiche_patient {
  color: rgb(128, 128, 128);
}
</style>
