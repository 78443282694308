// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.modal-mask[data-v-4f2a7d72] {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
}
.modal-wrapper[data-v-4f2a7d72] {
    display: table-cell;
    vertical-align: middle;
}
`, "",{"version":3,"sources":["webpack://./project/private/Resources/js/components/modals/PatientSignUpSuccess.vue"],"names":[],"mappings":";AA6DA;IACA,eAAA;IACA,aAAA;IACA,MAAA;IACA,OAAA;IACA,WAAA;IACA,YAAA;IACA,mCAAA;IACA,cAAA;IACA,4BAAA;AACA;AAEA;IACA,mBAAA;IACA,sBAAA;AACA","sourcesContent":["<template>\n    <Modal\n        :title=\"$t('_confirmation')\"\n        :isOpen=\"isOpen\"\n        @close=\"$emit('close')\"\n    >\n\n        <div v-if=\"(typeof(solutions) !== 'undefined' && solutions.includes(6))\">\n            <h3 class=\"pt-0\">{{ $t('_224') }}</h3>\n            <p>\n                {{ $t('mdc_registration') }}\n            </p>\n        </div>\n\n        <div v-if=\"(typeof(solutions) !== 'undefined' && solutions.includes(4))\">\n            <h3 class=\"pt-0\">{{ $t('_95') }}</h3>\n            <p>\n                {{ $t('cgm_registration') }}\n            </p>\n        </div>\n\n        <p v-else>\n            {{ $t('registration_over') }}\n        </p>\n\n        <button\n            class=\"btn\"\n            type=\"button\"\n            @click=\"$emit('close')\"\n        >\n            {{ $t('confirm') }}\n        </button>\n    </Modal>\n</template>\n\n<script>\nimport Modal from '@components/modals/Modal';\n\nexport default {\n    components: { Modal },\n\n    props: {\n        isOpen: {\n            type: Boolean,\n            default: false,\n        },\n\n        context: {\n            type: String,\n            required: true,\n        },\n\n        solutions: {\n            type: Array,\n            required: true,\n        },\n    },\n};\n</script>\n\n<style scoped>\n.modal-mask {\n    position: fixed;\n    z-index: 9998;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, .5);\n    display: table;\n    transition: opacity .3s ease;\n}\n\n.modal-wrapper {\n    display: table-cell;\n    vertical-align: middle;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
