var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "input input-colapsed mt-0 mb-3" }, [
    _c(
      "label",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !!_vm.label,
            expression: "!!label",
          },
        ],
        staticClass: "input-label",
        class: { asteriskRequired: _vm.required },
        attrs: { for: _vm.id || _vm.name },
      },
      [_vm._v("\n        " + _vm._s(_vm.label) + "\n    ")]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "file-text d-flex align-items-center" }, [
      _c("div", { staticClass: "file-upload__btn btn mr-3" }, [
        _vm._v("\n            " + _vm._s(_vm.$t("_219")) + "\n        "),
      ]),
      _vm._v(" "),
      _c("input", {
        staticClass: "custom-file-input position-absolute",
        attrs: {
          type: "file",
          id: _vm.id || _vm.name,
          name: _vm.name,
          accept: _vm.accept,
          multiple: _vm.multiple,
          disabled: _vm.disabled,
          required: _vm.required,
        },
        on: { change: _vm.handleInput },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "file-upload__name" }, [
        _c("a", { attrs: { href: _vm.fileurl, target: "_blank" } }, [
          _c("label", { staticClass: "filename-caption mb-0" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.filename || _vm.$t("_218")) +
                "\n                "
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.filename
        ? _c(
            "a",
            {
              staticClass: "ml-3",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.clearFile.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n            (Supprimer)\n        ")]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }