<template>
    <div>
        <div class="row mt-5">
            <div class="col pl-0">
                <h2>{{ $t('_logbook_title_pro_parameters') }}</h2>
            </div>
        </div>
        <div class="row">
            <div v-if="logbookParameters.hba1c" class="col-sm-12 col-md-4 col-lg-4 d-flex pl-0">
                <label class="mt-auto mb-2 mr-3">HbA1c</label>
                <Input name="hba1c" id="hba1c" type="number" onwheel="return false;" label=""
                    v-model="savedParameters.patient_inputs.hba1c" @input="updateData()" />
                <p class="mt-auto mb-2 ml-3">%</p>
            </div>
            <div class="col-sm-12 col-md-5 col-lg-5 d-flex">
                <label class="mt-auto mb-2 mr-3">Date de prise</label>
                <Input name="date_parameter" type="date" label="" v-model="savedParameters.date" @input="updateData()" />
            </div>
        </div>
        <div class="row">
            <div v-if="logbookParameters.weight" class="col-sm-12 col-md-4 col-lg-4 d-flex pl-0">
                <label class="mt-auto mb-2 mr-3">{{ $t('_weight') }}</label>
                <Input name="weight" id="weight" label="" v-model="savedParameters.patient_inputs.weight"
                    @input="updateData()" />
                <p class="mt-auto mb-2 ml-3">kg</p>
            </div>
            <div v-if="logbookParameters.height" class="col-sm-12 col-md-4 col-lg-4 d-flex pl-0">
                <label class="mt-auto mb-2 mr-3">{{ $t('_height') }}</label>
                <Input name="height" id="height" label="" v-model="savedParameters.patient_inputs.height"
                    @input="updateData()" />
                <p class="mt-auto mb-2 ml-3">cm</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ParametersPatient",
    props: {
        logbookParameters: {
            type: Object
        },
        savedParameters: {
            type: Object
        }
    },

    mounted() {

    },
    methods: {
        updateData() {
            this.$emit('updateData', this.savedParameters)
        }
    }
}
</script>

<style></style>