<template>
    <svg :width="size + 'em'" :height="size + 'em'" viewBox="0 0 14 8" xmlns="http://www.w3.org/2000/svg" fill="none">
        <path d="M1 1L7 7L13 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
</template>
<script>
export default {
  name: "IconArrowDown",
  status: "prototype",
  release: "1.0.0",
  props: {
    /**
     * The size of the icon. Defaults to 24.
     * `1(default), 1.5, 2, 3, 4`
     */
    size: {
      type: [Number, String],
      default: '1'
    },
  },
}
</script>


