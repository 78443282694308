<template>
    <div>
        <div class="row mt-5">
            <div class="col-md-12 pl-0">
                <h2>{{ $t('_episodes_hypoglycemic') }}</h2>
            </div>
        </div>
        <div class="row" v-for="(episode, index) in episodes" :key="index">
            <div class="col-sm-12 col-md-4 col-lg-4 d-flex pl-0">
                <Input name="blood_sugar" type="number" onwheel="return false;" id="blood_sugar" label=""
                    v-model.number="episode.blood_sugar" @input="updateData()" />
                <p class="mt-auto mb-2 ml-3">g/l </p>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-4 d-flex">
                <label class="mt-auto mb-2 mr-3">{{ $t('_date_of') }}</label>
                <Input type="date" name="episode_date" label="" v-model="episode.episode_date" @input="updateData()" />
            </div>
            <div class="col-sm-12 col-md-4 col-lg-4 d-flex align-items-end">
                <label class="mt-auto mb-2 mr-3">{{ $t('_hour') }}</label>
                <vue-timepicker name="episode_time" id="episode_time" v-model="selectedTime[index]" :minute-interval="30"
                    @change="selectEpisodeTime(index)"></vue-timepicker>
            </div>
        </div>
        <div class="row my-5">
            <div class="col pl-0">
                <span role=button class="text-secondary d-flex">
                    <p class="font-weight-bold" @click="addEpisodeLine()"><i class="icon small">add</i><span class="mb-2">{{
                        $t('_add_episode') }}</span></p>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "EpisodesPatient",
    props: {
        logbookParameters: {
            type: Object
        },
        savedEpisodes: {
            type: Array
        }
    },
    data() {
        return {
            selectedTime: [],
            episodes: [
                {
                    blood_sugar: null,
                    episode_date: null,
                    episode_time: null,
                    already_sent: false,
                }
            ]
        }
    },
    mounted() {
        this.getEpisodes();
        this.episodes.forEach((element, index) => {
            if (element.episode_time) {
                let timeArr = element.episode_time.split(":")
                this.selectedTime[index] = {
                    HH: timeArr[0],
                    mm: timeArr[1],
                }
            }
        });
    },
    methods: {
        getEpisodes() {
            if (this.savedEpisodes.length) {
                this.episodes = this.savedEpisodes;
            }
        },
        addEpisodeLine() {
            this.episodes.push({
                blood_sugar: null,
                episode_date: null,
                episode_time: null,
                already_sent: false,
            })
        },
        selectEpisodeTime(index) {
            this.episodes[index].episode_time = this.selectedTime[index].HH + ":" + this.selectedTime[index].mm;
            this.updateData();
        },
        updateData() {
            this.$emit('updateData', this.episodes)
        }
    }
}
</script>

<style></style>