<template>
    <div class="card">
        <div
            class="card-header"
        >
            <a
                href="#"
                @click.prevent="isOpened = !isOpened"
            >
              {{ $t('_216')}}
            </a>
        </div>

        <div class="card-body" v-show="isOpened">
            <p>{{ $t('_0') }}</p>
            <p>{{ $t('_1') }}</p>
            <p>{{ $t('_2') }}</p>

            <ul>
                <li>{{ $t('_3') }}</li>
                <li>{{ $t('_4') }}</li>
                <li>{{ $t('_5') }}</li>
                <li>{{ $t('_6') }}</li>
                <li>{{ $t('_7') }}</li>
                <li>{{ $t('_8') }}</li>
                <li>{{ $t('_9') }}</li>
            </ul>

            <p>{{ $t('_10') }}</p>
            <p>{{ $t('_11') }}</p>
            <p>{{ $t('_12') }}</p>
            <p>{{ $t('_13') }}</p>
            <p>{{ $t('_14') }}</p>
            <p>{{ $t('_15') }}</p>
            <p>{{ $t('_16') }}</p>
            <p>{{ $t('_17') }}</p>

            <a href="#" @click.prevent="isOpened = false">
                Fermer
            </a>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isOpened: false,
        }
    }
};
</script>

<style scoped>
.card {
    margin: 0;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, .125);
    max-width: 100%;
}
</style>
