<template>
    <div>
        <div class="row justify-content-start">
            <div class="col-sm-12 col-md-5 col-lg-5">
                <div class="row justify-content-end">
                    <slot name="firstColumn"/>
                </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 text-white rounded mt-3 h-50" :style="{backgroundColor: background}">
                <div class="row">
                    <slot name="secondColumn"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
name: "InsulinBlock", 
props: {
    background: {
        type: String
    }
}


}
</script>

<style scoped>
</style>