var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pagination" },
    [
      _c(
        "div",
        {
          staticClass: "changepage changepagevue",
          class: { hidden: _vm.page == 1 },
          on: {
            click: function ($event) {
              _vm.page = 1
            },
          },
        },
        [_vm._v("1")]
      ),
      _vm._v(" "),
      _vm._l(
        _vm.pages.slice(_vm.page - 1, _vm.page + 5),
        function (pageNumber) {
          return _c(
            "div",
            {
              staticClass: "changepage changepagevue",
              class: { active: pageNumber == _vm.page },
              on: {
                click: function ($event) {
                  return _vm.changePage(pageNumber)
                },
              },
            },
            [_vm._v(_vm._s(pageNumber) + "\n  ")]
          )
        }
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }