<template>
    <div class="input mb-3" :class="{'input-colapsed': collapsed, 'mt-6': customMargin, 'mt-0': !customMargin }">
        <label
            class="input-label"
            :for="id || name"
            :class="{ 'asteriskRequired': required }"
        >
            <span>{{ label }}</span>
            <TooltipSVG
                v-if="tooltip"
                v-tooltip="tooltip"
                class="input-tooltip"
                fill="#525ca3"
            />
        </label>
        <input
            class="input-body"
            :class="bold ? 'font-weight-bold' : ''"
            :id="id || name"
            :type="type"
            :name="name"
            :value="value"
            :required="required"
            :readonly="readonly"
            :disabled="disabled"
            :autocomplete="autocomplete"
            :min="min"
            :max="max"
            :bold="bold"
            @input="handleInput"
            @focus="event => $emit('focus', event)"
            @blur="event => $emit('blur', event)"
            @keydown="event => $emit('keydown', event)"
            @wheel.prevent
        />
    </div>
</template>

<script>
import TooltipSVG from '../../../../../public/img/icon/tooltip-icon.svg';

export default {
    components: { TooltipSVG },

    props: {
        value: {
            type: [String, Number],
        },

        label: {
            type: String,
            required: true,
        },

        name: {
            type: String,
            required: true,
        },

        id: {
            type: String,
            required: false,
        },

        type: {
            type: String,
            default: 'text',
        },

        min: {
            type: String,
            default: '',
        },

        max: {
            type: String,
            default: '',
        },

        required: {
            type: Boolean,
            default: false,
        },

        readonly: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        autocomplete: {
            type: String,
            default: 'on',
        },

        tooltip: {
            type: [String, Object],
            default: null,
        },
        bold: {
            type: Boolean, 
            default: false
        }, 
        collapsed: {
            type: Boolean, 
            default: true
        },

        customMargin: {
            type: Boolean, 
            default: false
        }
    },

    methods: {
        handleInput(event) {
            this.$emit('input', event.target.value);
        },
    },
}
</script>

<style scoped>
.input-colapsed {
    padding-top: 10px;
}

.input.autocomplete .input-body {
    padding-left: 30px;
    /* padding-top: 0 */
}

.input .input-body:disabled + .input-label,
.input .input-body:read-only + .input-label {
    top: 10px !important;
}

.input .input-body:disabled + .input-label {
    color: rgba(77, 77, 77, .38) !important;
}

.input-tooltip {
    margin-left: 7px;
    width: 18px;
    height: 18px;
}

input {
    color: inherit;
}

.input .input-body{
    z-index: 799;
    position: relative;
    padding: 3px;
    font-size: 16px;
    margin-top: 5px;
}

input:disabled, input:read-only{
    z-index: 0 !important;
}
</style>
