<template>
    <div class="input input-colapsed mt-0 mb-3">
        <label
            v-show="!!label"
            class="input-label"
            :for="id || name"
            :class="{ 'asteriskRequired': required }"
        >
            {{ label }}
        </label>

        <div class="file-text d-flex align-items-center">
            <div class="file-upload__btn btn mr-3">
                {{ $t('_219') }}
            </div>

            <input
                type="file"
                class="custom-file-input position-absolute"
                :id="id || name"
                :name="name"
                :accept="accept"
                :multiple="multiple"
                :disabled="disabled"
                :required="required"
                @change="handleInput"
            />
        
            <div class="file-upload__name">
                <a :href="fileurl" target="_blank">
                    <label class="filename-caption mb-0">
                        {{ filename || $t('_218') }}
                    </label>
                </a>
            </div>

            <a
                v-if="filename"
                href="#"
                class="ml-3"
                @click.prevent="clearFile"
            >
                (Supprimer)
            </a> 

        </div>
    </div>
</template>

<script>
export default {
    model: {
        prop: 'value',
        event: 'change',
    },

    props: {
        value: {
            type: File,
            default: null,
        },

        label: {
            type: String,
            required: false,
        },

        name: {
            type: String,
            required: true,
        },

        id: {
            type: String,
            required: false,
        },

        // TODO: Handle uploading multiple files
        multiple: {
            type: Boolean,
            default: false,
        },

        accept: {
            type: String,
            required: false,
        },

        required: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            filename: null,
            fileurl: null,
        }
    },

    methods: {
        handleInput(event) {
            
            const file = event.target.files[0];

            if (file) {
                this.filename = file.name;
                this.fileurl = URL.createObjectURL(file);
                this.$emit('change', file);
            }
        },

        clearFile() {
            this.filename = null;
            this.$emit('change', null);
        },
    },
}
</script>

<style>
.custom-file-input {
    cursor: pointer;
    width:15%;
}
</style>
