var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "select",
      {
        style: { width: _vm.width },
        attrs: { name: "", id: "" },
        on: { change: _vm.change },
      },
      _vm._l(_vm.datas, function (data, index) {
        return _c(
          "option",
          {
            key: index,
            attrs: { disabled: data.disabled },
            domProps: { value: data.value, selected: _vm.selectedOption(data) },
          },
          [_vm._v("\n        " + _vm._s(data.text) + "\n    ")]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }