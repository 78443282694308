<template>
    <div class="page-section h-100">
      <div v-if="formOpen != true" class="change-mode" id="modifIdentityInfo">
        <div v-on:click="openForm()">
          <i class="fas fa-pencil-alt"></i>
          <span class="change-tooltip">{{ $t('_18') }}</span>
        </div>
      </div>

      <h2>{{ $t('_19') }}</h2>
      <form
        enctype="multipart/form-data"
        class="bg-white rounded-lg"
        method="post"
        :action="actionURL"
        @submit.prevent="submit"
      >
        <div class="col-md-12">
          <fieldset :disabled="formOpen === false">
            <InputGroup>
              <Input
                v-model="form.phone"
                :label="helperLabels.cellphone"
                name="phone"
                :disabled="formOpen === false"
                :readonly="formOpen === false"
                dir="ltr"
              />

              <Input
                v-model="form.phone2"
                :label="helperLabels.telephone"
                name="phone2"
                :disabled="formOpen === false"
                :readonly="formOpen === false"
                dir="ltr"
              />

              <Input
                class="mb-4"
                v-model="form.email"
                :label="helperLabels.email"
                name="email"
                :disabled="true"
                :readonly="true"
              />

              <Radio
                v-model="form.email_principale"
                value="1"
                name="email_principale"
                :disabled="formOpen === false"
                :readonly="formOpen === false"
                id="email_principale"
                :label="$t('_102')"
              />

              <Input
                class="mb-4"
                v-model="form.email2"
                :label="helperLabels.email2"
                name="email2"
                :disabled="formOpen === false"
                :readonly="formOpen === false"
              />

              <Radio
                v-model="form.email_principale"
                value="2"
                name="email_principale"
                :disabled="formOpen === false"
                :readonly="formOpen === false"
                id="email_principale2"
                :label="$t('_103')"
              />

              <Autocomplete
                v-model="form.wilaya_id"
                :items="wilayas.items"
                :formatItems="item => item.name"
                :initialValue="items => items.find(item => item.id === form.wilaya_id).name"
                @search="fetchWilayas"
                @select="() => {
                    towns.items = [];
                    $refs.townsInput.giveFocus();
                }"
                @clear="() => $refs.townsInput.handleClear()"
                @loading="wilayas.loading = true"
                @focus="() => !wilayas.items.length && fetchWilayas()"
                :debounce="500"
                :bindValueOnSelect="item => item.id"
                :showValueOnSelect="item => item.name"
                :loadingState="wilayas.loading"
                :label="$t('_104')"
                name="wilaya"
                required
              />

              <Autocomplete
                v-model="form.town_id"
                :items="towns.items"
                :formatItems="item => item.name"
                :initialValue="items => items.find(item => item.id === form.town_id).name"
                @search="fetchTowns"
                @select="item => form.postalCode = item.code_postal"
                @clear="form.postalCode = ''"
                @loading="towns.loading = true"
                @focus="() => !towns.items.length && fetchTowns()"
                :disabled="!form.wilaya_id.length"
                :debounce="500"
                :bindValueOnSelect="item => item.id"
                :showValueOnSelect="item => item.name"
                :loadingState="towns.loading"
                ref="townsInput"
                :label="$t('_105')"
                name="town"
                required
              />

              <Input
                v-model="form.postalCode"
                :label="$t('_106')"
                :disabled="formOpen === false"
                :readonly="formOpen === false"
                name="postalCode"
                required
              />

              <Input
                v-model="form.address"
                :label="$t('_107')"
                :disabled="formOpen === false"
                :readonly="formOpen === false"
                name="address"
              />
            </InputGroup>

            <h3 class="title_fiche_patient">{{ $t('_20') }}</h3>

            <InputGroup
              :label="$t('_108')"
              required
            >
              <Checkbox
                v-for="(communication, index) in communications"
                :key="index"
                v-model="form.communications"
                :value="index"
                :name="communication"
                :id="communication"
                :label="communication"
                :disabled= "((index == 4 && disable == true)  || (index == 3 && phoneDisable == true)) || (form.type_patient === 'hta' && index == 1) || (form.type_patient === 'hta' && index == 2)"
                :clear = "((index == 4 && disable == true) || (index == 3 && phoneDisable == true))"
              />
            </InputGroup>

            <h3 class="title_fiche_patient asteriskRequired">{{ $t('_21') }}</h3>
            <InputGroup>
              <Radio
                v-model="form.lang"
                value="fr"
                name="lang"
                id="lang"
                :disabled="formOpen === false"
                :readonly="formOpen === false"
                required
                :label="$t('_109')"
              />

              <Radio
                v-model="form.lang"
                value="ar"
                name="lang"
                id="lang2"
                :disabled="formOpen === false"
                :readonly="formOpen === false"
                required
                :label="$t('_110')"
              />

              <!--<Checkbox
                v-model="form.langExtra"
                value="kabyle"
                name="langExtra"
                id="lang-extra"
                :disabled="formOpen === false"
                :readonly="formOpen === false"
                :label="$t('_111')"
              />-->
              <Checkbox
                v-model="form.langExtra"
                value="kabyle"
                name="langExtra"
                id="lang-extra"
                :disabled="true"
                :readonly="true"
                :label="$t('_111')"
              />
            </InputGroup>
            <h3 class="title_fiche_patient asteriskRequired">{{ helperLabels.helper }}</h3>
            <InputGroup required>
              <Radio
                v-model="form.helper"
                :value="1"
                name="helper"
                id="helper1"
                :label="$t('_112')"
                :checked="isPatientMinor"
                :disabled="isPatientMinor"
              />

              <Radio
                v-model="form.helper"
                :value="0"
                name="helper"
                id="helper2"
                :label="$t('_113')"
                :disabled="isPatientMinor"
              />
            </InputGroup>
          </fieldset>
          <fieldset v-if="formOpen == true" :disabled="formOpen === false">
            <Submit
              class="text-center"
              :label="$t('_195')"
              :disabled="form.communications.length < 1"
            />
          </fieldset>
        </div>
      </form>
    </div>
</template>

<script>
import InputGroup from '../inputs/InputGroup';
import Input from '../inputs/Input';
import Radio from '../inputs/Radio';
import Autocomplete from '../inputs/Autocomplete';
import Checkbox from '../inputs/Checkbox';
import FileUpload from '../inputs/FileUpload';
import Submit from '../inputs/Submit';
import {toFormData} from "../../helpers";

export default {
  name: "ContactForm",

  components: {
    InputGroup,
    Input,
    Radio,
    Autocomplete,
    Checkbox,
    FileUpload,
    Submit,
  },

  props: {
    context: {
      type: String,
      required: true,
    },

    user: {
      type: Object,
      required: true,
    },

    uuid: {
      type: String,
      required: true,
    },

    data_wilayas: {
      type: Array,
      required: true,
      loading: false,
    },

    data_towns: {
      type: Array,
      required: true,
      loading: false,
    },

    editModeActivated: {
      type: Function,
    },
  },

  data() {
    return {
      loading: true,
      formOpen: false,
      disable: true,
      phoneDisable: true,
      helperortutor: '',
      communications: [],
      birthdate: this.user.birthdate,
      faceToFace:true,
      changeCount: 0,
      actionURL: "/ws/update_patient.php",

      form: {
        patient_id: this.user.patient_id,
        communications: this.user.communications,
        form: 'form_fiche_contact',
        phone: this.user.phone,
        phone2: this.user.phone2,
        email: this.user.email,
        email2: this.user.email2,
        email_principale: this.user.main_email,
        wilaya_id: this.user.wilaya_id,
        town_id: this.user.commune_id,
        lang: this.user.language,
        langExtra: parseInt(this.user.kabyle),
        helper: this.user.helper,
        consent_type: this.user.consent_type,
        postalCode: this.data_towns.find(item => item.id == this.user.commune_id).code_postal,
        address: this.user.address,
        type_patient: sessionStorage.getItem('fds')
      },

      wilayas: {
        items: this.data_wilayas,
        loading: false,
      },

      towns: {
        items: this.data_towns,
        loading: false,
      }
    }
  },

  watch:{
    'form.email2': {
      handler: function(val, oldVal) {
        if(this.user.email == "") {
          if(val.length != 0){
            this.disable = false;
          }else{
            this.disable = true;
          }
        }
      },
      deep: true
    },
    'form.phone': {
      handler: function(val, oldVal) {
          if(val.length != 0){
            this.phoneDisable = false;
          }else{
            this.phoneDisable = true;
          }
      },
      deep: true
    },
  },
  computed: {
    diabetesTreatmentJoined: function() {
      if(this.user.diabetes.diabetes_active == true) {
        var data_treatments = [];
        Object.keys(this.user.diabetes.treatments).forEach(key => {
            data_treatments.push(key)
        });
        return data_treatments;
      }

      return ''
    },

    prefix() {
      return this.context === 'hcp' ? '/professionnel' : '/cro';
    },

    isPatientMinor() {
      if (!this.birthdate.length) {
        return false;
      }

      const birthdate = this.$moment(this.birthdate, 'YYYY-MM-DD');
      const now = this.$moment();

      return now.diff(birthdate, 'years') < 18;
    },
    helperLabels() {
      const isTutor = this.isPatientMinor ? 1 : 0;
      const helper = this.$i18n.t(isTutor ? '_211' : '_212');

      const cellphone = this.form.helper === '1' ?
        this.$i18n.t(isTutor ? '_200' : '_201') :
        this.$i18n.t('_199');
      const telephone = this.form.helper === '1' ?
        this.$i18n.t(isTutor ? '_203' : '_204') :
        this.$i18n.t('_202');
      const email = this.form.helper === '1' ?
        this.$i18n.t(isTutor ? '_206' : '_207') :
        this.$i18n.t('_205');
      const email2 = this.form.helper === '1' ?
        this.$i18n.t(isTutor ? '_209' : '_210') :
        this.$i18n.t('_208');

      return {helper, cellphone, telephone, email, email2}
    },

    
  },
  mounted() {
    this.fetchWilayas();
    this.getCommunicationList();
    this.enableFaceToFace();
    this.enableEmail();
    this.enablePhone();
    if (this.user && this.user.has_diabete) {
    // CHECK IF DIABETES PROGRAM IS Diab eCare OR NOT
      if(this.user.diabetes.programs["Diab eCare"] == 1) {
        // CHECK THE CONDITIONS OF CELLPHONE OR TELEPHONE
        if (this.user.phone == '') {
          if ( this.user.communications.includes("3") ) {
            var index = this.user.communications.indexOf("3");
            this.user.communications.splice(index, 1);
            this.changeCount++;
          }
        }
        // CHECK EMAIL IS EXIST OR NOT EXIST
        if ( this.user.email == "" && this.user.email2 == "" ) {
          if ( this.user.communications.includes("4") ) {
            var index = this.user.communications.indexOf("4");
            this.user.communications.splice(index, 1);
            this.changeCount++;
          }
        }
        if( this.changeCount > 0 ) {
          this.submit();
        }
      } else {
        this.user.communications.length = 0;
        this.disable = true;
        this.faceToFace = true;
        this.phoneDisable = true;
      }
    }
  },

  methods: {
    openForm: function () {
      this.formOpen = true
      this.editModeActivated()
    },

    enableEmail(){
      if(this.form.communications.includes('4')){
        this.disable = false;
      } else if(this.user.email !== "" || this.user.email2 !== "") {
        this.disable = false;
      }
    },

    enablePhone(){
      if(this.form.communications.includes('3')){
        this.phoneDisable = false;
      } else if(this.user.phone !== "") {
        this.phoneDisable = false;
      }
    },
    
    fetchWilayas(term) {
      this.wilayas.loading = true;

      const endpoint = this.prefix + '/ws/get_liste_wilaya.php';

      this.$axios.get(`${endpoint}?term=${term || ''}`)
        .then(response => {
          this.wilayas.items = [];
          this.wilayas.items = response.data;
          this.wilayas.loading = false;
        })
        .catch(e => {
          this.wilayas.items = [];
          this.wilayas.loading = false;
        });
    },

    fetchTowns(term) {
      this.towns.loading = true;

      const endpoint = this.prefix + '/ws/get_liste_commune.php';
      const params = [
        '?wilaya_id=' + (this.form.wilaya_id || ''),
        '&term=' + (term || ''),
      ];

      this.$axios.get(endpoint + params.join(''))
        .then(response => {
          this.towns.items = response.data;
          this.towns.loading = false;
        })
        .catch(e => {
          this.towns.items = [];
          this.towns.loading = false;
        });
    },

    getCommunicationList() {
      const endpoint = '/ws/get_all_communications.php';

      this.$axios.get(endpoint)
        .then(response => {
          this.communications = response.data.data;
        })
        .catch(e => {
          console.error(e);
        });
    },

    enableFaceToFace() {
      if (this.user.has_diabete) {
        if (Object.keys(this.user.diabetes.treatments).length == 1 && Object.keys(this.user.diabetes.treatments).includes('Apidra')) {
        this.faceToFace = true;
        } else if (Object.keys(this.user.diabetes.treatments).includes('Lantus') && this.user.diabetes.insulin_less_than_3_months == 1) {
          this.faceToFace = true;
        } else {
          this.faceToFace = false;
        }
      }
    },

    submit(event) {
      this.loading = true;
      let formData = toFormData(this.form)
      if (formData.get("helper") === "" || formData.get("helper") === null) {
        formData.set("helper", "0");
      }
      this.$axios.post(this.actionURL, formData)
        .then(response => {
          this.loading = false;
          this.formOpen = false;
          this.editModeActivated()
        })
        .catch(e => {
          const errors = e.response.data;
          for (const input in errors) {
            if (input == 'communications') {
              window.showAlert(
                  input,
                  // TODO: implement error messages with translation
                  // library
                  `Choix des canaux ETP / Formations : ${errors[input][0]}`
              );
            } else {
              window.showAlert(
                  input,
                  // TODO: implement error messages with translation
                  // library
                  `${input}: ${errors[input].join(', ')}`
              );
            }
          }

          this.errors = errors;
          this.loading = false;
          this.formOpen = false;
          this.editModeActivated()
        });
    },
  }
}
</script>

<style scoped>
.title_fiche_patient {
  font-weight: bolder;
  color: rgba(77, 77, 77, 0.87);
}
</style>
