var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex align-items-center justify-content-between" },
    [
      _c("p", { staticClass: "font-weight-bold m-0" }, [
        _vm._v(_vm._s(_vm.textBefore)),
      ]),
      _vm._v(" "),
      _c("Input", {
        staticClass: "input-width p-0",
        attrs: {
          name: _vm.name,
          id: _vm.id,
          label: "",
          value: _vm.value,
          readonly: _vm.readonly,
          bold: _vm.bold,
        },
        on: { input: _vm.handleInput },
      }),
      _vm._v(" "),
      _c("p", { staticClass: "m-0" }, [_vm._v(_vm._s(_vm.textAfter))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }