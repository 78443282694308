<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="size + 'em'" :height="size + 'em'" viewBox="0 0 20 20" fill="none">
        <rect width="20" height="20" rx="5" fill="white"/>
        <path d="M4.16699 10H15.8337" stroke="#646464" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
  name: "IconWhiteMinus",
  status: "prototype",
  release: "1.0.0",
  props: {
    /**
     * The size of the icon. Defaults to 24.
     * `1(default), 1.5, 2, 3, 4`
     */
    size: {
      type: [Number, String],
      default: '1'
    },
  },
}
</script>