<template>
    <div class="input mt-0">
        <input
            class="input-body"
            type="checkbox"
            v-model="_model"
            :id="id"
            :name="name"
            :value="value"
            :checked="_model"
            :required="required"
            :disabled="disabled"
        />

        <label
            :for="id"
            class="input-label"
        >
            {{ label }}

            <TooltipSVG
                v-if="tooltip"
                v-tooltip="tooltip"
                class="input-tooltip"
                fill="#525ca3"
            />
        </label>

    </div>
</template>

<script>
import TooltipSVG from '../../../../../public/img/icon/tooltip-icon.svg';

export default {
    components: { TooltipSVG },

    model: {
        prop: 'model',
        event: 'change',
    },

    props: {
        model: {
            type: [Boolean, Array, Number, String],
            default: false,
        },

        value: {
            type: [Boolean, String, Number, Object],
            default: '',
        },

        label: {
            type: String,
            required: true,
        },

        id: {
            type: [Number, String],
            required: true,
        },

        name: {
            type: String,
            required: true,
        },

        required: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        clear: {
            type: Boolean,
            default: false,
        },

        set: {
            type: Boolean,
            default: false,
        },

        tooltip: {
            type: [String, Object],
            default: null,
        },
    },

    computed: {
        _model: {
            get() {
                return this.model;
            },

            set(value) {
                this.handleInput(value);
            },
        },
    },

    watch: {
        clear: {
            immediate: true,

            handler(condition) {

                if (!condition) {
                    return;
                }

                if (this._model instanceof Array) {
                    this._model = this._model.filter(
                        value => value !== this.value
                    );
                }

                if (typeof this._model === 'boolean') {
                    this._model = false;
                }
            },
        },

        set: {
            immediate: true,

            handler(condition) {
                if (!condition) {
                    return;
                }

                if (
                    this._model instanceof Array &&
                    !this._model.includes(this.value)
                ) {
                    this._model = [...this._model, this.value];
                }

                if (typeof this._model === 'boolean') {
                    this._model = true;
                }
            },
        },

        disabled: {
            immediate: true,

            handler(condition) {

                if (!condition) {
                    return;
                }

                if (this._model instanceof Array) {
                    this._model = this._model.filter(
                        value => value !== this.value
                    );
                }

                if (typeof this._model === 'boolean') {
                    this._model = false;
                }
            },
        },
    },

    methods: {
        handleInput(value) {
            this.$emit('change', value);
        }
    },

};
</script>

<style scoped>
.input input[type="checkbox"] + .input-label {
    display: inline-block;
    align-items: center;
    margin-left: 1em;
}

.input input[type="checkbox"] + .input-label .input-tooltip {
    margin-left: 7px;
    width: 18px;
    height: 18px;
}

.input input[type="checkbox"][disabled] + .input-label {
    color: rgba(77,77,77,0.38) !important;
}

.input input[type="checkbox"].input-body + .input-label::before {
    height: 18px;
    width: 18px;
    top: 1px;
}

.input input[type="checkbox"].input-body + .input-label::after {
    font-size: 16px;
    top: 5px;
    left: 2.5px;
}

.input input[type="checkbox"].input-body:focus+.input-label,
.input input[type="checkbox"].input-body + .input-label::before {
    top: 0px !important;
}
</style>
