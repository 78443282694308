import Vue from 'vue';
import VueI18n from 'vue-i18n'
import PatientSignUp from './pages/PatientSignUp';
import PatientCard from './pages/PatientCard';
import PatientMedicalUpdate from './pages/PatientMedicalUpdate';
import ProfessionnalLogBook from './pages/ProfessionnalLogBook';
import PatientLogbook from './pages/PatientLogbook';
import Questionnaires from './pages/Questionnaires';
import PreRegistrationHcp from './pages/PreRegistrationHcp';
import ParrainageHcp from './pages/ParrainageHcp';
import ModalQuestions from './modals/ModalQuestions';
import ModalCgu from './modals/ModalCgu';
import IconBook from '@components/icons/IconBook';
import IconPaper from '@components/icons/IconPaper';
import IconGrow from '@components/icons/IconGrow';
import IconArrowDown from '@components/icons/IconArrowDown';
import IconWhitePlus from '@components/icons/IconWhitePlus';
import IconWhiteMinus from '@components/icons/IconWhiteMinus';
import Input from '@components/inputs/Input';
import Checkbox from '@components/inputs/Checkbox';
import Radio from '@components/inputs/Radio';
import InputGroup from '@components/inputs/InputGroup';
import SelectGlobal from '@components/inputs/SelectGlobal';
import Axios from 'axios';
import Moment from 'moment';
import tooltip from 'v-tooltip';
import 'v-tooltip/dist/v-tooltip.css';
import messages from '../../../public/locales/fr/translation.json';
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css'
import 'core-js/stable'; 
import 'regenerator-runtime/runtime';

Vue.prototype.$axios = Axios;
Vue.prototype.$moment = Moment;

Vue.use(tooltip);
Vue.use(VueI18n);
Vue.component('PatientSignUp', PatientSignUp);
Vue.component('PatientCard', PatientCard);
Vue.component('PatientMedicalUpdate', PatientMedicalUpdate);
Vue.component('Questionnaires', Questionnaires);
Vue.component('ModalQuestions', ModalQuestions);
Vue.component('ModalCgu', ModalCgu);
Vue.component('ProfessionnalLogBook', ProfessionnalLogBook);
Vue.component('PatientLogbook', PatientLogbook);
Vue.component('PreRegistrationHcp', PreRegistrationHcp);
Vue.component('ParrainageHcp', ParrainageHcp);

// Icons
Vue.component('IconBook', IconBook);
Vue.component('IconPaper', IconPaper);
Vue.component('IconGrow', IconGrow);
Vue.component('IconArrowDown', IconArrowDown);
Vue.component('IconWhitePlus', IconWhitePlus);
Vue.component('IconWhiteMinus', IconWhiteMinus);

// Inputs 
Vue.component('Input', Input);
Vue.component('Checkbox', Checkbox);
Vue.component('Radio', Radio);
Vue.component('InputGroup', InputGroup);
Vue.component('SelectGlobal', SelectGlobal);

// Vue2 Time Picker
Vue.component('VueTimepicker', VueTimepicker);


const el = document.querySelector('#__app');

export const i18n = new VueI18n({
  locale: 'fr', // set locale
  fallbackLocale: 'fr',
})

const loadedLanguages = [] // our default language that is preloaded

function setI18nLanguage(lang) {
  i18n.locale = lang
  Axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync(lang = 'fr') {
  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    if (i18n.locale !== lang) {
      setI18nLanguage(lang)
    }

    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language hasn't been loaded yet
  return import(`../../../public/locales/${lang}/translation.json`).then(
    messages => {
      i18n.setLocaleMessage(lang, messages.default)
      loadedLanguages.push(lang)
      return setI18nLanguage(lang)
    }
  )
}

if (el) {
  const app = new Vue({ i18n });
  const interval = setInterval(() => {
    if (translationReady) {
      loadLanguageAsync(sessionStorage.language).then(() => app.$mount(el));
      clearInterval(interval);
    }
  }, 250);
}
