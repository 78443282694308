<template>
  <div class="page-section">
    <p>Pour parrainer un confrère afin qu'il s'inscrive à la plateforme Rafiq Essiha, veuillez rentrer son email ci-dessous :</p>
    <form
      enctype="multipart/form-data"
      class="bg-white p-4 rounded-lg"
      method="post"
      action="/professionnel/ws/parrainage_confrere.php"
      @submit.prevent="event => !loading && submit(event)"
  >
    <fieldset :disabled="loading">
      <Input
          v-model="form.email"
          label="Email"
          name="email"
          :custom-margin='true'
          required
      />

      <InputGroup
        label="A quel programme souhaitez-vous parrainer votre confrère ?"
        required
      >
        <Radio
            v-model="form.program"
            value="hta"
            name="program"
            id="hta"
            label="HTA eCare"
        />
        <Radio
            v-model="form.program"
            value="diab"
            name="program"
            id="diab"
            label="Diab eCare"
        />
        <Radio
            v-model="form.program"
            value="diab-hta"
            name="program"
            id="diab-hta"
            label="Diab eCare & HTA eCare"
        />
      </InputGroup>
      <br>
      <p>En cliquant sur ce bouton, il recevra un mail avec toutes les informations lui permettant de s'inscrire à la plateforme</p>
      <br>
      </fieldset>

      <fieldset :disabled="loading">
        <Submit
            class="text-center"
            label="Valider le parrainage"
        />
      </fieldset>
    </form>
  </div>
</template>

<script>
import Input from '@components/inputs/Input';
import Radio from '@components/inputs/Radio';
import Submit from '@components/inputs/Submit';
import { toFormData } from '@helpers';

export default {
  components: {
    Input,
    Radio,
    Submit,
  },
  name: "ParrainageHcp",

  data() {
    return {
      loading: false,
      form: {
        email: '',
        program: '',  
      },
      formSuccess: false,
      errors: null,
    }
  },

  methods: {
    submit(event) {
    this.formSuccess = false;
    this.errors = null;
    this.loading = true;

    this.$axios.post('/professionnel/ws/parrainage_confrere.php', toFormData(this.form))
        .then(response => {
          this.formSuccess = true;
          this.loading = false;
          showInfo('Information', 'Le mail a bien été envoyé.', "#dialogBox .dialog-body")
          this.form = { email: '', program: '' };   
        })
        .catch(e => {
          this.loading = false;
          const errors = e.response.data;
            showAlert('Avertissement', e.response.data, "#dialogBox .dialog-body")
          this.errors = errors;
        });
      },
    }
  }
</script>

<style scoped>

</style>
