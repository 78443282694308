var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-section" }, [
    _c(
      "form",
      {
        staticClass: "bg-white p-4 rounded-lg",
        attrs: {
          enctype: "multipart/form-data",
          method: "post",
          action: "/professionnel/ws/pre_inscription_hcp.php",
        },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return ((event) => !_vm.loading && _vm.submit(event)).apply(
              null,
              arguments
            )
          },
        },
      },
      [
        _c(
          "fieldset",
          { attrs: { disabled: _vm.loading } },
          [
            _c("Input", {
              attrs: {
                label: "Nom",
                name: "name",
                id: "name",
                "custom-margin": true,
                required: "",
              },
              model: {
                value: _vm.form.name,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "name", $$v)
                },
                expression: "form.name",
              },
            }),
            _vm._v(" "),
            _c("Input", {
              attrs: {
                label: "Prénom",
                name: "firstname",
                id: "firstname",
                "custom-margin": true,
                required: "",
              },
              model: {
                value: _vm.form.firstname,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "firstname", $$v)
                },
                expression: "form.firstname",
              },
            }),
            _vm._v(" "),
            _c("Autocomplete", {
              attrs: {
                items: _vm.specialities.items,
                formatItems: (item) => item.value,
                debounce: 500,
                bindValueOnSelect: (item) => item.value,
                showValueOnSelect: (item) => item.value,
                loadingState: _vm.specialities.loading,
                label: "Spécialité",
                name: "speciality",
                required: "",
              },
              on: {
                search: _vm.fetchSpecialities,
                loading: function ($event) {
                  _vm.wilayas.loading = true
                },
                focus: () =>
                  !_vm.specialities.items.length && _vm.fetchSpecialities(),
              },
              model: {
                value: _vm.form.speciality,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "speciality", $$v)
                },
                expression: "form.speciality",
              },
            }),
            _vm._v(" "),
            _c("Autocomplete", {
              attrs: {
                items: _vm.wilayas.items,
                formatItems: (item) => item.name,
                debounce: 500,
                bindValueOnSelect: (item) => item.id,
                showValueOnSelect: (item) => item.name,
                loadingState: _vm.wilayas.loading,
                label: _vm.$t("_180"),
                name: "wilaya",
                required: "",
              },
              on: {
                search: _vm.fetchWilayas,
                select: () => {
                  _vm.towns.items = []
                  _vm.$refs.townsInput.giveFocus()
                },
                clear: () => _vm.$refs.townsInput.handleClear(),
                loading: function ($event) {
                  _vm.wilayas.loading = true
                },
                focus: () => !_vm.wilayas.items.length && _vm.fetchWilayas(),
              },
              model: {
                value: _vm.form.wilaya,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "wilaya", $$v)
                },
                expression: "form.wilaya",
              },
            }),
            _vm._v(" "),
            _c("Autocomplete", {
              ref: "townsInput",
              attrs: {
                items: _vm.towns.items,
                formatItems: (item) => item.name,
                debounce: 500,
                bindValueOnSelect: (item) => item.id,
                showValueOnSelect: (item) => item.name,
                loadingState: _vm.towns.loading,
                disabled: !_vm.form.wilaya,
                label: "Ville",
                name: "town",
                required: "",
              },
              on: {
                search: _vm.fetchTowns,
                select: (item) => (_vm.form.postalCode = item.code_postal),
                clear: function ($event) {
                  _vm.form.postalCode = ""
                },
                loading: function ($event) {
                  _vm.towns.loading = true
                },
                focus: () => !_vm.towns.items.length && _vm.fetchTowns(),
              },
              model: {
                value: _vm.form.town,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "town", $$v)
                },
                expression: "form.town",
              },
            }),
            _vm._v(" "),
            _c("Input", {
              attrs: {
                label: "Adresse",
                name: "address",
                id: "address",
                "custom-margin": true,
                required: "",
              },
              model: {
                value: _vm.form.address,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "address", $$v)
                },
                expression: "form.address",
              },
            }),
            _vm._v(" "),
            _c("Input", {
              attrs: {
                label: "Code postal",
                name: "postal",
                id: "postal",
                "custom-margin": true,
                required: "",
              },
              model: {
                value: _vm.form.postalCode,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "postalCode", $$v)
                },
                expression: "form.postalCode",
              },
            }),
            _vm._v(" "),
            _c("Input", {
              attrs: {
                label: "Téléphone",
                name: "phone",
                id: "phone",
                "custom-margin": true,
              },
              model: {
                value: _vm.form.phone,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "phone", $$v)
                },
                expression: "form.phone",
              },
            }),
            _vm._v(" "),
            _c("Input", {
              attrs: {
                label: "Email",
                name: "email",
                id: "email",
                "custom-margin": true,
                required: "",
              },
              model: {
                value: _vm.form.email,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "email", $$v)
                },
                expression: "form.email",
              },
            }),
            _vm._v(" "),
            _vm.program == "hta" || _vm.program == "diab-hta"
              ? _c("Checkbox", {
                  attrs: {
                    name: "consent_diab",
                    id: "consent_hta",
                    label: _vm.consent_hta,
                  },
                  model: {
                    value: _vm.form.consent_hta,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "consent_hta", $$v)
                    },
                    expression: "form.consent_hta",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.program == "diab" || _vm.program == "diab-hta"
              ? _c("Checkbox", {
                  attrs: {
                    name: "consent_diab",
                    id: "consent_diab",
                    label: _vm.consent_diab,
                  },
                  model: {
                    value: _vm.form.consent_diab,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "consent_diab", $$v)
                    },
                    expression: "form.consent_diab",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("br"),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "fieldset",
          { attrs: { disabled: _vm.loading } },
          [
            _c("Submit", {
              staticClass: "text-center",
              attrs: { label: "Valider le parrainage" },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }