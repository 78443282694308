var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "border rounded row" }, [
    _c("div", {
      staticClass: "container-image rounded-left col-sm-12 col-md-2 col-lg-2",
      style: {
        backgroundImage: `url(${require("../../../../../../public/img/carnet/" +
          _vm.getImage)})`,
      },
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-sm-12 col-md-10 col-lg-10 pt-3" },
      [
        _c("h2", { staticClass: "mb-0 ml-3" }, [
          _vm._v(_vm._s(_vm.$t("_" + _vm.getLabel))),
        ]),
        _vm._v(" "),
        _c(_vm.getLabel, {
          tag: "component",
          attrs: { period: _vm.period },
          on: {
            updateData: function ($event) {
              return _vm.$emit("updateData", $event)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }