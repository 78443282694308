var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-header" }, [
      _c(
        "a",
        {
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              _vm.isOpened = !_vm.isOpened
            },
          },
        },
        [_vm._v("\n          " + _vm._s(_vm.$t("_216")) + "\n        ")]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isOpened,
            expression: "isOpened",
          },
        ],
        staticClass: "card-body",
      },
      [
        _c("p", [_vm._v(_vm._s(_vm.$t("_0")))]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.$t("_1")))]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.$t("_2")))]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [_vm._v(_vm._s(_vm.$t("_3")))]),
          _vm._v(" "),
          _c("li", [_vm._v(_vm._s(_vm.$t("_4")))]),
          _vm._v(" "),
          _c("li", [_vm._v(_vm._s(_vm.$t("_5")))]),
          _vm._v(" "),
          _c("li", [_vm._v(_vm._s(_vm.$t("_6")))]),
          _vm._v(" "),
          _c("li", [_vm._v(_vm._s(_vm.$t("_7")))]),
          _vm._v(" "),
          _c("li", [_vm._v(_vm._s(_vm.$t("_8")))]),
          _vm._v(" "),
          _c("li", [_vm._v(_vm._s(_vm.$t("_9")))]),
        ]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.$t("_10")))]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.$t("_11")))]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.$t("_12")))]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.$t("_13")))]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.$t("_14")))]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.$t("_15")))]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.$t("_16")))]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.$t("_17")))]),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                _vm.isOpened = false
              },
            },
          },
          [_vm._v("\n            Fermer\n        ")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }