<template>
    <div class="input-autocomplete">
        <div v-if="loadingState" class="signal">
            <div
                :style="{ color: '#cfbb97' }"
                class="spinner-border spinner-border-sm"
                role="status"
            >
                <span class="sr-only">{{ $t('_93') }}</span>
            </div>
        </div>

        <div v-else-if="!loadingState && selectedValue" class="signal">
            <CloseSVG
                @click="handleClear"
                @keydown="handleClear"
                :style="{ color: '#525ca3' }"
                tabindex="0"
                type="button"
                aria-label="Clear"
                aria-hidden="true"
                height="20"
                width="20"
            />
        </div>

        <div v-else class="signal">
            <SearchSVG width="20" height="20" />
        </div>

        <Input
            type="search"
            autocomplete="off"
            ref="input"
            class="autocomplete"
            :label="label"
            :id="id"
            :name="name"
            :value="selectedValue"
            @input="value => {
                $emit('loading');
                debounceHandleSearch(value);
            }"
            @focus="handleFocus"
            @blur="handleBlur"
            @keydown.backspace="() => !!selectedValue && handleClear()"
            :required="required"
            :readonly="!!selectedValue"
            :disabled="disabled"
            :custom-margin="true"
        />

        <ul
            v-show="!selectedValue && !!items.length && isOpen"
            class="ui-menu ui-widget ui-widget-content ui-autocomplete ui-front"
            tabindex="-1"
        >
            <li
                class="ui-menu-item"
                v-for="(item, index) in items"
                :key="index"
                @click.prevent="() => handleSelect(item)"
                @keydown.enter="() => handleSelect(item)"
                tabindex="0"
            >
                <div class="ui-menu-item-wrapper">
                    {{ formatItems(item) }}
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import Input from './Input';
import SearchSVG from '../../../../../public/img/icon/search.svg';
import CloseSVG from '../../../../../public/img/icon/times.svg';

export default {
    components: { Input, SearchSVG, CloseSVG },

    props: {
        value: {
            type: [String, Number],
            default: '',
        },

        label: {
            type: String,
            required: true,
        },

        name: {
            type: String,
            required: true,
        },

        id: {
            type: String,
            required: false,
        },

        required: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        items: {
            type: Array,
            default: [],
        },

        initialValue: {
            type: [Function, String, Array, Object],
            default: () => {},
        },

        bindValueOnSelect: {
            type: Function,
            default: item => item,
        },

        showValueOnSelect: {
            type: Function,
            default: item => item,
        },

        formatItems: {
            type: Function,
            default: item => item,
        },

        loadingState: {
            type: Boolean,
            default: false,
        },

        debounce: {
            type: Number,
            default: 0,
        }
    },

    data() {
        return {
            isOpen: false,
            selectedValue: '',
        };
    },

    created() {
        this.debounceHandleSearch = _.debounce(
            this.handleSearch,
            this.debounce
        );
    },

    mounted() {
          setTimeout(() => {
            this.selectedValue = this.initialValue(this.items);
        }, 1500);

        document.addEventListener('click', this.close);
        document.addEventListener('keydown', this.close);
    },

    destroyed() {
        document.removeEventListener('click', this.close);
        document.removeEventListener('keydown', this.close);
    },

    methods: {
        handleSearch(value) {
            this.$emit('search', value);
            this.isOpen = true;
        },

        handleSelect(item) {
            this.$emit('input', this.bindValueOnSelect(item));
            this.$emit('select', item);
            this.selectedValue = this.showValueOnSelect(item);
            this.isOpen = false;
        },

        handleClear() {
            this.$emit('input', '');
            this.$emit('clear');
            this.selectedValue = '';
            this.giveFocus();
        },

        handleFocus(event) {
            this.$emit('focus', event);
            this.isOpen = true;
        },

        handleBlur(event) {
            this.$emit('blur', event);
        },

        close(event) {
            if (!this.isOpen || event.defaultPrevented) {
                return;
            }

            if (
                (event.type === 'click' && !this.$el.contains(event.target)) ||
                (event.type === 'keydown' && event.key === 'Escape')
            ) {
                this.isOpen = false;
                event.preventDefault();
            }
        },

        giveFocus() {
            this.$nextTick(
                () => this.$refs.input.$el.querySelector('input').focus()
            );
        },
    }
}
</script>

<style scoped>
.ui-autocomplete {
    max-height: 250px;
}

.input-autocomplete {
    position: relative;
}

.input-autocomplete .signal {
    z-index: 1;
    position: absolute;
    top: 44px;
    left: 3px;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ui-menu {
    top: 100%;
    width: 100%;
    z-index: 999;
}
</style>
