import axios from 'axios';

const BASE_URL = sessionStorage.getItem('glycemic_logbook_api_url');
const TOKEN = sessionStorage.getItem('glycemic_logbook_api_token'); 
const HEADER = { headers: { Authorization: `Bearer ` + TOKEN }};


export default {
  get(api_url, id) {
    return axios.get(`${BASE_URL}/${api_url}/${id}`, HEADER);
  },

  getPatient(api_url, id, date_url, date) {
    return axios.get(`${BASE_URL}/${api_url}/${id}${date_url}${date}`, HEADER);
  },

  create(api_url, data) {
    return axios.post(`${BASE_URL}/${api_url}`, data, HEADER);
  },

  delete(api_url, id, api_url_2, id_item) {
    return axios.delete(`${BASE_URL}/${api_url}/${id}/${api_url_2}/${id_item}`, HEADER);
  }, 

  updateSection(api_url, id, api_url_2, id2, data) {
    return axios.put(`${BASE_URL}/${api_url}/${id}/${api_url_2}/${id2}`, data, HEADER);
  },

  createSection(api_url, id, api_url_2, data) {
    return axios.post(`${BASE_URL}/${api_url}/${id}/${api_url_2}`, data, HEADER);
  },

  createSectionId(api_url, id, api_url_2, id2, api_url_3, data) {
    return axios.post(`${BASE_URL}/${api_url}/${id}/${api_url_2}/${id2}/${api_url_3}`, data, HEADER);
  },

  updateSectionId(api_url, id, api_url_2, id2, api_url_3, id3, data) {
    return axios.put(`${BASE_URL}/${api_url}/${id}/${api_url_2}/${id2}/${api_url_3}/${id3}`, data, HEADER);
  },
};