var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "input input-colapsed mt-0 mb-3" },
    [
      _c(
        "label",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !!_vm.label,
              expression: "!!label",
            },
          ],
          staticClass: "input-label",
          class: {
            asteriskRequired: _vm.required,
            "font-weight-bold": _vm.bold,
          },
        },
        [_vm._v("\n        " + _vm._s(_vm.label) + "\n    ")]
      ),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }