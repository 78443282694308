<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="size + 'em'" :height="size + 'em'" viewBox="0 0 20 20" fill="none">
        <path d="M11.6667 1.66666H5.00001C4.55798 1.66666 4.13406 1.84225 3.8215 2.15481C3.50894 2.46737 3.33334 2.8913 3.33334 3.33332V16.6667C3.33334 17.1087 3.50894 17.5326 3.8215 17.8452C4.13406 18.1577 4.55798 18.3333 5.00001 18.3333H15C15.442 18.3333 15.866 18.1577 16.1785 17.8452C16.4911 17.5326 16.6667 17.1087 16.6667 16.6667V6.66666L11.6667 1.66666Z" stroke="currentColor" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.6667 1.66666V6.66666H16.6667" stroke="currentColor" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.3333 10.8333H6.66666" stroke="currentColor" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.3333 14.1667H6.66666" stroke="currentColor" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.33332 7.5H7.49999H6.66666" stroke="currentColor" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
  name: "IconPaper",
  status: "prototype",
  release: "1.0.0",
  props: {
    /**
     * The size of the icon. Defaults to 24.
     * `1(default), 1.5, 2, 3, 4`
     */
    size: {
      type: [Number, String],
      default: '1'
    },
  },
}
</script>


