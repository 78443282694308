var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "input mt-0" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm._model,
          expression: "_model",
        },
      ],
      staticClass: "input-body",
      attrs: {
        type: "checkbox",
        id: _vm.id,
        name: _vm.name,
        required: _vm.required,
        disabled: _vm.disabled,
      },
      domProps: {
        value: _vm.value,
        checked: _vm._model,
        checked: Array.isArray(_vm._model)
          ? _vm._i(_vm._model, _vm.value) > -1
          : _vm._model,
      },
      on: {
        change: function ($event) {
          var $$a = _vm._model,
            $$el = $event.target,
            $$c = $$el.checked ? true : false
          if (Array.isArray($$a)) {
            var $$v = _vm.value,
              $$i = _vm._i($$a, $$v)
            if ($$el.checked) {
              $$i < 0 && (_vm._model = $$a.concat([$$v]))
            } else {
              $$i > -1 &&
                (_vm._model = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
            }
          } else {
            _vm._model = $$c
          }
        },
      },
    }),
    _vm._v(" "),
    _c(
      "label",
      { staticClass: "input-label", attrs: { for: _vm.id } },
      [
        _vm._v("\n        " + _vm._s(_vm.label) + "\n\n        "),
        _vm.tooltip
          ? _c("TooltipSVG", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.tooltip,
                  expression: "tooltip",
                },
              ],
              staticClass: "input-tooltip",
              attrs: { fill: "#525ca3" },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }