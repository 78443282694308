<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="size + 'em'" :height="size + 'em'" viewBox="0 0 20 20" fill="none">
        <path d="M1.66666 2.5H6.66666C7.55072 2.5 8.39857 2.85119 9.02369 3.47631C9.64881 4.10143 10 4.94928 10 5.83333V17.5C10 16.837 9.73661 16.2011 9.26776 15.7322C8.79892 15.2634 8.16304 15 7.5 15H1.66666V2.5Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18.3333 2.5H13.3333C12.4493 2.5 11.6014 2.85119 10.9763 3.47631C10.3512 4.10143 10 4.94928 10 5.83333V17.5C10 16.837 10.2634 16.2011 10.7322 15.7322C11.2011 15.2634 11.837 15 12.5 15H18.3333V2.5Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
  name: "IconBook",
  status: "prototype",
  release: "1.0.0",
  props: {
    /**
     * The size of the icon. Defaults to 24.
     * `1(default), 1.5, 2, 3, 4`
     */
    size: {
      type: [Number, String],
      default: '1'
    },
  },
}
</script>


