// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
select[data-v-27c45d36] {
  border: none;
  color: gray;
  border-bottom: 1px solid lightgray;
}
`, "",{"version":3,"sources":["webpack://./project/private/Resources/js/components/inputs/SelectGlobal.vue"],"names":[],"mappings":";AA6CA;EACA,YAAA;EACA,WAAA;EACA,kCAAA;AACA","sourcesContent":["<template>\n  <div>\n    <select name=\"\" id=\"\" @change=\"change\" :style=\"{width: width}\">\n      <option v-for=\"(data, index) in datas\" :key=\"index\"\n          :value=\"data.value\"\n          :disabled=\"data.disabled\" \n          :selected=\"selectedOption(data)\">\n          {{ data.text }}\n      </option>\n    </select>\n  </div>\n</template>\n\n<script>\nexport default {\n  name: \"SelectGlobal\",\n\n  props: {\n    value: {\n      type: [String, Number],\n      default: null,\n    },\n    datas: {\n      type: Array,\n      required: true,\n    }, \n    width: {\n      default: \"50%\"\n    }\n  }, \n  methods: {\n    selectedOption(option) {\n      if (this.value) {\n        return option.value === this.value;\n      }\n      return false;\n    },\n    change(e) {\n      this.$emit(\"input\", e.target.value);\n    },\n  }\n}\n</script>\n\n<style scoped>\nselect {\n  border: none;\n  color: gray;\n  border-bottom: 1px solid lightgray;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
