<template>
    <div class="input input-colapsed mt-0 mb-3">
        <button
            class="btn"
            type="submit"
            :disabled="disabled"
            :readonly="false"
            @click="onClick"
        >
            {{ label }}
        </button>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: true,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        onClick: {
            type: Function,
            default: () => {},
        },
    },
};
</script>
