<template>
    <div class="input mt-0">
        <input
            class="input-body"
            type="radio"
            :id="id"
            :name="name"
            :value="value"
            :checked="model === value"
            :required="required"
            :disabled="disabled"
            @change="handleInput"
        />

        <label
            :for="id"
            class="input-label radio-label"
        >
            {{ label }}
        </label>
        <TooltipSVG
            v-if="tooltip"
            v-tooltip="tooltip"
            class="input-tooltip"
            fill="#525ca3"
        />
    </div>
</template>

<script>
import TooltipSVG from '../../../../../public/img/icon/tooltip-icon.svg';
export default {
    components: { TooltipSVG },
    model: {
        prop: 'model',
        event: 'change',
    },

    props: {
        model: {
            type: [Boolean, String, Number],
            default: '',
        },

        value: {
            type: [Boolean, String, Number],
            default: '',
        },

        label: {
            type: String,
            required: true,
        },

        id: {
            type: String,
            required: true,
        },

        name: {
            type: String,
            required: true,
        },

        checked: {
            type: Boolean,
            default: false,
        },

        required: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },
        tooltip: {
            type: [String, Object],
            default: null,
        },
    },

    watch: {
        checked: {
            handler(isChecked) {
                isChecked && this.handleInput();
            },

            immediate: true,
        },
    },

    methods: {
        handleInput() {
            this.$emit('change', this.value);
        },
    },
};
</script>

<style scoped>
.input input[type="radio"][disabled] + .input-label {
    color: rgba(77,77,77,0.38) !important;
}
.input-tooltip {
    position: relative;
    margin-left: 8px;
    width: 18px;
    float: right;
    height: 25px;
    bottom: 32px;
}

.radio-label {
    margin-left: 1em;
}

.radio-label::before {
    top: 1px !important;
}

.radio-label::after {
    top: 6px !important;
}
</style>
