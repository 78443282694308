<template>
    <div>
        <Modal v-if="isOpen" :is-open="isOpen" :title="$t('_' + data.label)" @close="$emit('close')">
            <fieldset>
                <label>{{$t('_glycemie')}}</label>
                <InputGroup required>
                    <div v-if="data.form_inputs.blood_sugar.type === 'select_multiple'">
                        <Checkbox
                        v-for="answer in data.form_inputs.blood_sugar.values"
                        :key="answer"
                        :value="answer"
                        name="bloodSugar"
                        :id="answer"
                        :label="$t('_' + answer + '_' + data.label)"
                        v-model="form_inputs.blood_sugar"
                        />
                    </div>
                    <div v-else>
                        <Checkbox
                        v-for="(answer, index) in data.form_inputs.blood_sugar.values"
                        :key="answer"
                        :value="answer"
                        name="bloodSugar"
                        :id="answer"
                        :label="$t('_' + answer)"
                        :ref="'check' + index"
                        @change="checkBoxSugar($event,index)"
                        v-model="form_inputs.blood_sugar"
                        />
                    </div>
                </InputGroup>
            </fieldset>
            <fieldset>
                <label>{{$t('_dose_of_insulin')}}</label>
                <InputGroup required>
                    <Checkbox
                    v-for="answer in data.form_inputs.insulin.values"
                    :key="answer"
                    :value="answer"
                    name="insuline"
                    :id="answer"
                    :label="$t('_' + answer)"
                    v-model="form_inputs.insulin"
                    :disabled="!treatmentValues.includes(answer)"
                    :clear="!treatmentValues.includes(answer)"
                    />
                </InputGroup>
            </fieldset>
            <div class="d-flex justify-content-end mt-4">
                <div class="text-align-center">
                    <button class="btn btn-outline-primary" @click="$emit('close')">{{$t('_cancel')}}</button>
                </div>
                <div class="ml-4">
                    <button class="btn" @click="onSave('save')">{{$t('_165')}}</button>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
import Modal from '@components/modals/Modal';
import apiCall from '../../apiLogbook'; 

export default {
    components: {
        Modal
    },
    props: {
        data: {
            type: Object
        },
        isOpen: {
            type: Boolean,
            default: false,
        }, 
        periodArray: {
            type: Array
        }, 
        treatments: {
            type: Array
        },
    },
    data() {
        return {
            patient_id: sessionStorage.getItem('patient_id'),
            treatmentValues: [],
            form_inputs: {
                insulin: [],
                blood_sugar: []
            }
        }
    },
    mounted() {
        this.getPeriodData();
        // for disabling the Toujeo in modal
        this.getElement('Toujeo', true);
        this.treatments.forEach(treatment => {
            this.treatmentValues.push(treatment.name_fr_FR);
        });
    },
    methods: {
        getPeriodData() {
          if(this.periodArray.length) {
              const result = this.periodArray.find(({ period_type_id }) => period_type_id === this.data.id)

              if(result !== undefined) {
                  this.form_inputs = { ...result.form_inputs }; 
              }
          }
        },
        getElement(el, boolean) {
            return document.getElementById(el).disabled = boolean;
        },
        checkBoxSugar(value) {
            value == 'yes' ? this.getElement('no', true) : this.getElement('no', false);
            value == 'no' ? this.getElement('yes', true) : this.getElement('yes', false);
        }, 
        onSave() {
          this.$emit('save', { period_type_id: this.data.id, form_inputs: this.form_inputs })
        }
    }
};
</script>

<style>
</style>
