var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "input-autocomplete" },
    [
      _vm.loadingState
        ? _c("div", { staticClass: "signal" }, [
            _c(
              "div",
              {
                staticClass: "spinner-border spinner-border-sm",
                style: { color: "#cfbb97" },
                attrs: { role: "status" },
              },
              [
                _c("span", { staticClass: "sr-only" }, [
                  _vm._v(_vm._s(_vm.$t("_93"))),
                ]),
              ]
            ),
          ])
        : !_vm.loadingState && _vm.selectedValue
        ? _c(
            "div",
            { staticClass: "signal" },
            [
              _c("CloseSVG", {
                style: { color: "#525ca3" },
                attrs: {
                  tabindex: "0",
                  type: "button",
                  "aria-label": "Clear",
                  "aria-hidden": "true",
                  height: "20",
                  width: "20",
                },
                on: { click: _vm.handleClear, keydown: _vm.handleClear },
              }),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "signal" },
            [_c("SearchSVG", { attrs: { width: "20", height: "20" } })],
            1
          ),
      _vm._v(" "),
      _c("Input", {
        ref: "input",
        staticClass: "autocomplete",
        attrs: {
          type: "search",
          autocomplete: "off",
          label: _vm.label,
          id: _vm.id,
          name: _vm.name,
          value: _vm.selectedValue,
          required: _vm.required,
          readonly: !!_vm.selectedValue,
          disabled: _vm.disabled,
          "custom-margin": true,
        },
        on: {
          input: (value) => {
            _vm.$emit("loading")
            _vm.debounceHandleSearch(value)
          },
          focus: _vm.handleFocus,
          blur: _vm.handleBlur,
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k(
                $event.keyCode,
                "backspace",
                undefined,
                $event.key,
                undefined
              )
            )
              return null
            return (() => !!_vm.selectedValue && _vm.handleClear()).apply(
              null,
              arguments
            )
          },
        },
      }),
      _vm._v(" "),
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.selectedValue && !!_vm.items.length && _vm.isOpen,
              expression: "!selectedValue && !!items.length && isOpen",
            },
          ],
          staticClass:
            "ui-menu ui-widget ui-widget-content ui-autocomplete ui-front",
          attrs: { tabindex: "-1" },
        },
        _vm._l(_vm.items, function (item, index) {
          return _c(
            "li",
            {
              key: index,
              staticClass: "ui-menu-item",
              attrs: { tabindex: "0" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return (() => _vm.handleSelect(item)).apply(null, arguments)
                },
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return (() => _vm.handleSelect(item)).apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "ui-menu-item-wrapper" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.formatItems(item)) +
                    "\n            "
                ),
              ]),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }