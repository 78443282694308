var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isOpen
        ? _c(
            "Modal",
            {
              attrs: {
                "is-open": _vm.isOpen,
                title: _vm.$t("_" + _vm.data.label),
              },
              on: {
                close: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [
              _c(
                "fieldset",
                [
                  _c("label", [_vm._v(_vm._s(_vm.$t("_glycemie")))]),
                  _vm._v(" "),
                  _c("InputGroup", { attrs: { required: "" } }, [
                    _vm.data.form_inputs.blood_sugar.type === "select_multiple"
                      ? _c(
                          "div",
                          _vm._l(
                            _vm.data.form_inputs.blood_sugar.values,
                            function (answer) {
                              return _c("Checkbox", {
                                key: answer,
                                attrs: {
                                  value: answer,
                                  name: "bloodSugar",
                                  id: answer,
                                  label: _vm.$t(
                                    "_" + answer + "_" + _vm.data.label
                                  ),
                                },
                                model: {
                                  value: _vm.form_inputs.blood_sugar,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form_inputs,
                                      "blood_sugar",
                                      $$v
                                    )
                                  },
                                  expression: "form_inputs.blood_sugar",
                                },
                              })
                            }
                          ),
                          1
                        )
                      : _c(
                          "div",
                          _vm._l(
                            _vm.data.form_inputs.blood_sugar.values,
                            function (answer, index) {
                              return _c("Checkbox", {
                                key: answer,
                                ref: "check" + index,
                                refInFor: true,
                                attrs: {
                                  value: answer,
                                  name: "bloodSugar",
                                  id: answer,
                                  label: _vm.$t("_" + answer),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.checkBoxSugar($event, index)
                                  },
                                },
                                model: {
                                  value: _vm.form_inputs.blood_sugar,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form_inputs,
                                      "blood_sugar",
                                      $$v
                                    )
                                  },
                                  expression: "form_inputs.blood_sugar",
                                },
                              })
                            }
                          ),
                          1
                        ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "fieldset",
                [
                  _c("label", [_vm._v(_vm._s(_vm.$t("_dose_of_insulin")))]),
                  _vm._v(" "),
                  _c(
                    "InputGroup",
                    { attrs: { required: "" } },
                    _vm._l(
                      _vm.data.form_inputs.insulin.values,
                      function (answer) {
                        return _c("Checkbox", {
                          key: answer,
                          attrs: {
                            value: answer,
                            name: "insuline",
                            id: answer,
                            label: _vm.$t("_" + answer),
                            disabled: !_vm.treatmentValues.includes(answer),
                            clear: !_vm.treatmentValues.includes(answer),
                          },
                          model: {
                            value: _vm.form_inputs.insulin,
                            callback: function ($$v) {
                              _vm.$set(_vm.form_inputs, "insulin", $$v)
                            },
                            expression: "form_inputs.insulin",
                          },
                        })
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex justify-content-end mt-4" }, [
                _c("div", { staticClass: "text-align-center" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-primary",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("_cancel")))]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "ml-4" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      on: {
                        click: function ($event) {
                          return _vm.onSave("save")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("_165")))]
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }