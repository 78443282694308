<template>
  <div class="w-20">
    <span role="button">
      <div 
      class="container_periode_top rounded-top" 
      :style="{ backgroundImage: `url(${require('../../../../../public/img/carnet/' + getImage)})` }" 
      @click="$emit('openModal')">
        <div :class="checked ? 'background-white-checked bg-white' : ''" class="d-flex justify-content-center align-items-center background-white rounded-top">
            <i class="fas fa-pencil-alt"></i> {{ $t('_edit') }}
        </div>
      </div>
      <div class="container_periode_bot shadow rounded-bottom d-flex justify-content-center align-items-center p-2">
        <input :id="'period'+ period.id" type="checkbox" v-model="checked" @change="check($event)">
        <p class="mb-0 ml-2">{{ $t('_' + period.label) }}</p>
      </div>
    </span>
  </div>
</template>

<script>
export default {
  name: "LogbookCard",

  props: {
    period: {
      type: Object
    }
  },
  data() {
    return {
      checked: ''
    }
  }, 
  computed: {
    getImage() {
      let image; 

        switch (this.period.id) {
          case 1: image = "matin.png"
            break;
          case 2: image = "midi.png"
            break;
          case 3: image = "apres-midi.png"
            break;
          case 4: image = "soir.png"
            break;
          case 5: image = "au-coucher.png"
            break;
          default: image = "matin.png"
            break;
        }
      return image
    }
  }, 
  methods: {
    check() {
      if(this.checked === true) {
        this.$emit('openModal')
      }
      else {
        this.$emit('deletePeriod')
      }
    },
    checkModal(value) {
      this.checked = value;
    }
  }
}
</script>

<style scoped>
.background-white {
  width: 180px; 
  height: 150px;
  opacity: 0;
}

.background-white-checked {
  width: 180px; 
  height: 150px;
  opacity: 0.8;
}

.background-white:hover {
  opacity: 0.8;
  background-color: white;
}

.container_periode_top {
  width: 180px; 
  height: 150px;
  color: transparent;
  background-size: cover;
}

.container_periode_top:hover {
  opacity: 1;
  color: #525CA3;
}

.container_periode_bot {
  color: #525CA3;
  width: 180px;
}

.container_periode_bot input {
  accent-color: #525CA3;
}

</style>
