<template>
    <div>

        <!--Blue Block-->

        <InsulinBlock :background="blueColor">
            <template v-slot:firstColumn>
                <div class="col-sm-12 col-md-8 col-lg-8 d-flex justify-content-end align-items-center mt-3">
                    <label class="font-weight-bold small">{{ $t('_upon') }} <span class="color_asterisk">*</span></label>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <Input label="" name="insulin_blood_sugar_above" :bold="true" v-model.number="data.increase_max.above"
                        @input="updateData()" />
                    <label>mg/dL</label>
                </div>
            </template>
            <template v-slot:secondColumn>
                <div class="col-sm-12 col-md-9 col-lg-9 p-0 pl-3">
                    <CustomInput name="insulin_blood_sugar_u" :text-before="$t('_rise_to')" text-after="U" :bold="true"
                        v-model.number="data.increase_max.U" @input="updateData()" />
                </div>
                <div class="col-sm-12 col-md-3 col-lg-3 d-flex justify-content-end align-items-center p-0 pr-3"
                    @click="addLine('blue')">
                    <icon-white-plus v-if="!addedBlueLine && !modalObjectif" />
                </div>
            </template>
        </InsulinBlock>
        <InsulinBlock v-if="addedBlueLine && data.increase" :background="blueColor">
            <template v-slot:firstColumn>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <Input label="" name="insulin_blood_sugar_increase_from" :bold="true"
                        v-model.number="data.increase.from" @input="updateData()" />
                    <label>mg/dL</label>
                </div>
                <div class="col-1 font-weight-bold justify-content-center align-items-end pt-5">
                    <p>-</p>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <Input label="" name="insulin_blood_sugar_increase_to" :bold="true" v-model.number="data.increase.to"
                        @input="updateData()" />
                    <label>mg/dL</label>
                </div>
            </template>
            <template v-slot:secondColumn>
                <div class="col-sm-12 col-md-9 col-lg-9 p-0 pl-3">
                    <CustomInput name="insulin_blood_sugar_increase_u" :text-before="$t('_rise_to')" text-after="U"
                        :bold="true" v-model.number="data.increase.U" @input="updateData()" />
                </div>
                <div class="col-sm-12 col-md-3 col-lg-3 d-flex justify-content-end align-items-center p-0 pr-3"
                    @click="removeLine('blue')">
                    <icon-white-minus v-if="!modalObjectif" />
                </div>
            </template>
        </InsulinBlock>

        <!--Green Block-->

        <InsulinBlock :background="greenColor">
            <template v-slot:firstColumn>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <Input label="" name="insulin_blood_sugar_normal_from" :bold="true" v-model.number="data.normal.from"
                        @input="updateData()" />
                    <label>mg/dL</label>
                </div>
                <div class="col-1 font-weight-bold justify-content-center align-items-end pt-5">
                    <p>-</p>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <Input label="" name="insulin_blood_sugar_normal.to" :bold="true" v-model.number="data.normal.to"
                        @input="updateData()" />
                    <label>mg/dL</label>
                </div>
            </template>
            <template v-slot:secondColumn>
                <div class="col-12 d-flex align-items-start justify-content-center font-weight-bold pt-3">
                    <p class="mt-1 mb-3">{{ $t('_no_modifications') }}</p>
                </div>
            </template>
        </InsulinBlock>

        <!--Red Block-->

        <InsulinBlock :background="redColor">
            <template v-slot:firstColumn>
                <div class="col-sm-12 col-md-8 col-lg-8 d-flex justify-content-end align-items-center mt-3">
                    <label class="font-weight-bold small">{{ $t('_under') }} <span class="color_asterisk">*</span></label>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4 mb-2">
                    <Input label="" name="insulin_blood_sugar_decrease_max_below" :bold="true"
                        v-model.number="data.decrease_max.below" @input="updateData()" />
                    <label>mg/dL</label>
                </div>
            </template>
            <template v-slot:secondColumn>
                <div class="col-sm-12 col-md-9 col-lg-9 p-0 pl-3">
                    <CustomInput name="insulin_blood_sugar_decrease_max_U" :text-before="$t('_low_to')" text-after="U"
                        :bold="true" v-model.number="data.decrease_max.U" @input="updateData()" />
                </div>
                <div class="col-sm-12 col-md-3 col-lg-3 d-flex justify-content-end align-items-center p-0 pr-3"
                    @click="addLine('red')">
                    <icon-white-plus v-if="!addedRedLine && !modalObjectif" />
                </div>
            </template>
        </InsulinBlock>
        <InsulinBlock v-if="addedRedLine && data.decrease" :background="redColor">
            <template v-slot:firstColumn>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <Input label="" name="insulin_blood_sugar_decrease_from" :bold="true"
                        v-model.number="data.decrease.from" @input="updateData()" />
                    <label>mg/dL</label>
                </div>
                <div class="col-1 font-weight-bold justify-content-center align-items-end pt-5">
                    <p>-</p>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <Input label="" name="insulin_blood_sugar_decrease_to" :bold="true" v-model.number="data.decrease.to"
                        @input="updateData()" />
                    <label>mg/dL</label>
                </div>
            </template>
            <template v-slot:secondColumn>
                <div class="col-sm-12 col-md-9 col-lg-9 p-0 pl-3">
                    <CustomInput name="insulin_blood_sugar_decrease_u" :text-before="$t('_low_to')" text-after="U"
                        :bold="true" v-model.number="data.decrease.U" @input="updateData()" />
                </div>
                <div class="col-sm-12 col-md-3 col-lg-3 d-flex justify-content-end align-items-center p-0 pr-3"
                    @click="removeLine('red')">
                    <icon-white-minus v-if="!modalObjectif" />
                </div>
            </template>
        </InsulinBlock>

    </div>
</template>

<script>
import CustomInput from '@components/inputs/CustomInput';
import InsulinBlock from '@components/forms/InsulinBlock';

export default {
    name: "InsulinRecommandation",
    components: {
        CustomInput,
        InsulinBlock
    },
    props: {
        modalObjectif: {
            type: Boolean,
            default: false
        },
        dataGlycemic: {
            type: [Object, Array]
        }
    },
    data() {
        return {
            addedRedLine: false,
            addedBlueLine: false,
            blueColor: '#2AA4BF',
            greenColor: '#2ABF52',
            redColor: '#A5211A',
            data: {
                increase_max: {
                    above: null,
                    U: null
                },
                normal: {
                    from: null,
                    to: null
                },
                decrease_max: {
                    below: null,
                    U: null
                }
            }
        }
    },
    created() {
        this.checkData();
    },
    methods: {
        checkData() {
            if (typeof this.dataGlycemic === 'object') {
                if (Object.keys(this.dataGlycemic).length !== 0) {
                    this.data = this.dataGlycemic;

                    if (this.data.decrease) {
                        this.addedRedLine = true;
                    }

                    if (this.data.increase) {
                        this.addedBlueLine = true;
                    }
                }
            }
        },
        updateData() {
            this.$emit('blockData', this.data)
        },
        addLine(line) {
            // line === 'red' ? this.addedRedLine = true : this.addedBlueLine = true;
            if (line === 'red') {
                this.addedRedLine = true;
            }
            if (line === 'blue') {
                this.addedBlueLine = true;
            }

            if (this.addedBlueLine) {
                this.data = {
                    ...this.data,
                    increase: { from: null, to: null, U: null }
                }
            }
            if (this.addedRedLine) {
                this.data = {
                    ...this.data,
                    decrease: { from: null, to: null, U: null }
                }
            }
        },
        removeLine(line) {
            line === 'red' ? this.addedRedLine = false : this.addedBlueLine = false;

            if (this.addedBlueLine === false) {
                delete this.data.increase;
            }
            else {
                delete this.data.decrease;
            }
        }
    }
}
</script>

<style scoped>
.color_asterisk {
    color: red;
}
</style>