<template>
    <div class="d-flex align-items-center justify-content-between">
        <p class="font-weight-bold m-0">{{ textBefore }}</p>
        <Input :name="name" :id="id" label="" class="input-width p-0" @input="handleInput" :value="value" :readonly="readonly" :bold="bold"/>
        <p class="m-0">{{ textAfter }}</p>
    </div>
</template>

<script>

export default {
name: "CustomInput", 
props: {
    textBefore: {
        type: String
    }, 
    textAfter: {
        type: String
    }, 
    name: {
        type: String
    }, 
    id: {
        type: String
    }, 
    value: {
        type: [String, Number],
    }, 
    modalObjectif: {
        type: Boolean, 
        default: false
    }, 
    readonly: {
        type: Boolean, 
        default: false
    }, 
    bold: {
        type: Boolean, 
        default: false
    }
}, 
methods: {
    handleInput (value) {
        this.$emit('input', value)
    }
}
}
</script>

<style scoped>
.input-width {
    width: 2rem;
}
</style>