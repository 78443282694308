// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.changepagevue[data-v-177616fb] {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./project/private/Resources/js/components/inputs/Pagination.vue"],"names":[],"mappings":";AAiFA;EACA,kBAAA;AACA","sourcesContent":["<template>\n    <div class=\"pagination\">\n      <div class=\"changepage changepagevue\" v-bind:class=\"{ 'hidden': page == 1 }\" @click=\"page = 1\">1</div>\n      <div class=\"changepage changepagevue\" v-for=\"pageNumber in pages.slice(page-1, page+5)\" @click=\"changePage(pageNumber)\"\n           v-bind:class=\"{ 'active': pageNumber == page }\">{{ pageNumber }}\n      </div>\n    </div>\n</template>\n\n<script>\nexport default {\n  props: {\n    contents: {\n      type: Array,\n      required: true,\n    },\n\n    perPage: {\n      type: Number,\n      required: true,\n    },\n  },\n\n  data() {\n    return {\n      page: 1,\n      pages: [],\n    }\n  },\n\n  created() {\n    this.setPages();\n    this.sendValues();\n  },\n\n  computed: {\n    displayContent() {\n      this.setPages();\n      this.sendValues();\n      return this.paginate(this.contents);\n    }\n  },\n\n  methods: {\n    setPages() {\n      this.pages = [];\n      let numberOfPages = Math.ceil(this.contents.length / this.perPage);\n      for (let index = 1; index <= numberOfPages; index++) {\n        this.pages.push(index);\n      }\n    },\n\n    paginate(content) {\n      let page = this.page;\n      let perPage = this.perPage;\n      let from = (page * perPage) - perPage;\n      let to = (page * perPage);\n\n      return content.slice(from, to);\n    },\n\n    sendValues() {\n      this.$emit('pages', this.paginate(this.contents));\n    },\n\n    changePage(value) {\n      this.page = value;\n\n      this.$emit('pages', this.paginate(this.contents));\n    }\n  },\n}\n\n</script>\n\n<style scoped>\n  .changepagevue {\n    text-align: center;\n  }\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
