<template>
  <div>
    <h1 class="mb-5">{{ $t('_my_logbook') }}</h1>
    <div class="page-section h-100">
      <div class="row">
        <div class="col-md-12">
          <div class="row mb-3">
            <!-- Date -->
            <div class="col-sm-6 col-md-4 col-lg-9 mb-3 p-0 ">
              <h2 class="mr-2">{{ $t('_date_today') }}</h2>
              <Input class="col-lg-3 col-md-4 col-sm6" name="dateLogbook" type="date" label="" v-model="logbookDate"
                @input="updateDate()" />
            </div>
            <div class="col-sm-12 col-md-3 col-lg-3">
              <button class="btn btn-outline-primary w-100" @click="isOpen = true">{{ $t('_see_objectives') }}</button>
            </div>
          </div>
          <div class="row p-0">
            <div class="col-md-12 p-0">
              <h2>{{ $t("_logbook_title_pro_periode") }}</h2>
            </div>
          </div>
          <div :key="updatePage + 1">
            <div class="row cards-scroll d-flex flex-md-column flex-nowrap flex-md-wrap">
              <div v-for="(period, index) in sortedPeriod" :key="index" class="col-12 mt-5">
                <div>
                  <LogbookPatient :id="period.period_type_id" :period="period" @updateData="updateData($event)" />
                </div>
              </div>
            </div>
            <div v-if="logbookParameters">
              <ParametersPatient :logbookParameters="logbookParameters" :savedParameters="savedParameters"
                @updateData="updateParameters($event)" />
            </div>
            <div v-if="logbookParameters.glycemic_episode">
              <EpisodesPatient :logbookParameters="logbookParameters" :savedEpisodes="savedEpisodes"
                @updateData="updateEpisodes($event)" :key="updatePage + 3" />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-center">
              <button class="btn" @click="submit()" :disabled="disabled">
                <i class="icon small">check</i>{{ $t("_save") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalObjectives :logbookObjectives="logbookObjectives" :logbookHba1c="logbookHba1c" :is-open="isOpen"
      @close="isOpen = false" :key="updatePage + 2" />
  </div>
</template>

<script>
import LogbookPatient from "@/components/logbook/patient/LogbookPatient";
import ModalObjectives from "@components/logbook/patient/ModalObjectives";
import EpisodesPatient from "@components/logbook/patient/EpisodesPatient";
import ParametersPatient from "@components/logbook/patient/ParametersPatient";
import apiCall from "../apiLogbook";

export default {
  name: "PatientLogBook",

  data() {
    return {
      p1: null,
      p2: null,
      updatePage: 0,
      patient_id: sessionStorage.getItem("patient_id"),
      token: sessionStorage.getItem("glycemic_logbook_api_token"),
      isOpen: false,
      logbook: {},
      logbookDate: new Date().toISOString().slice(0, 10),
      logbookParameters: {},
      logbookObjectives: {},
      logbookHba1c: {},
      filledPeriods: [],
      breakfastPeriod: {},
      lunchPeriod: {},
      snackPeriod: {},
      supperPeriod: {},
      nightPeriod: {},
      savedEpisodes: [],
      newEpisodes: [],
      periodArray: [],
      treatList: [],
      sortedPeriod: {},
      emptyParameters: {
        date: null,
        patient_inputs: {
            hba1c: null,
            weight: null,
            height: null
        }
      },
      savedParameters :{},
      newParameters: {},
      disabled: false,
    };
  },
  components: {
    LogbookPatient,
    ModalObjectives,
    EpisodesPatient,
    ParametersPatient
  },
  created() {
    this.getLogbookData();
  },
  methods: {
    getLogbookData() {
      this.savedEpisodes = [];
      this.savedParameters = this.emptyParameters

      apiCall.getPatient('api/glycemic_logbooks', this.patient_id, '?date=', this.logbookDate)
        .then((response) => {
          this.logbook = response.data;
          this.sortedPeriod = this.logbook.periods;
          this.sortedPeriod = this.sortedPeriod.sort((a, b) => a.period_type_id - b.period_type_id);
          this.logbookParameters = response.data.glycemic_logbook_parameters;
          if (response.data.glycemic_logbook_parameters.parameters_patient_inputs.length) {
            this.savedParameters = this.logbookParameters.parameters_patient_inputs[0];
          }
          this.logbookObjectives = response.data.glycemic_objectives[response.data.glycemic_objectives.length - 1].insulin_recommandations;
          this.logbookHba1c = response.data.glycemic_objectives[response.data.glycemic_objectives.length - 1].objectives.hba1c;

          if (this.logbook.glycemic_episodes.length) {
            this.savedEpisodes = response.data.glycemic_episodes;
          }

          for (var key in this.logbookObjectives) {
            if (Array.isArray(this.logbookObjectives[key]) && this.logbookObjectives[key].length === 0) {
              delete this.logbookObjectives[key];
            }
          }

          this.updatePage++
        })
        .catch((error) => { });
    },
    updateData(data) {
      switch (data.idObject.period_type_id) {
        case 1:
          this.breakfastPeriod = data;
          break;
        case 2:
          this.lunchPeriod = data;
          break;
        case 3:
          this.snackPeriod = data;
          break;
        case 4:
          this.supperPeriod = data;
          break;
        case 5:
          this.nightPeriod = data;
          break;
        default:
          data;
          break;
      }
    },
    updateEpisodes(episodes) {
      this.newEpisodes = episodes;
    },
    updateParameters(parameters) {
      this.newParameters = parameters;
    },
    updateDate() {
      this.getLogbookData()
      this.updatePage++
    },
    /**
     * Download data from the specified URL.
     *
     * @returns {Promise<string>} The data from the URL.
     */
    async savePeriods() {
      let count = 0;
      this.filledPeriods.length = 0;
      this.filledPeriods.push(this.breakfastPeriod, this.lunchPeriod, this.snackPeriod, this.supperPeriod, this.nightPeriod);
      this.filledPeriods.forEach(async (period, index) => {
        if (Object.keys(period).length !== 0) {
          count++;
          this.sortedPeriod.forEach(async (item, i) => {
            if (period.idObject.id == item.id) {
              count = i;
              return;
            }
          });

          let obj2 = this.sortedPeriod[count].form_inputs;
          let obj1 = period;

          const newObj = {
            "patient_inputs": {},
            "date": this.logbookDate
          };

          for (const key in obj2) {
            if (obj2.hasOwnProperty(key) && obj1.hasOwnProperty(key)) {
              newObj["patient_inputs"][key] = {};

              obj2[key].forEach((subKey) => {
                if (subKey == "2_hours_after") {
                  newObj["patient_inputs"][key][subKey] = obj1[key]["after"];
                } else if (subKey == "2_hours_before") {
                  newObj["patient_inputs"][key][subKey] = obj1[key]["before"];
                } else if (subKey == "on_an_empty_stomach") {
                  newObj["patient_inputs"][key][subKey] = obj1[key]["before"];
                } else if (subKey == "yes") {
                  newObj["patient_inputs"][key][subKey] = obj1[key]["before"];
                } else {
                  newObj["patient_inputs"][key][subKey] = obj1[key][subKey];
                }
              });
            }
          }
          if (period.idObject.period_patient_inputs_id != null) {
            let parameters = newObj;
            await apiCall.updateSectionId("api/glycemic_logbooks", this.patient_id, "periods", period.idObject.id, "period_patient_inputs", period.idObject.period_patient_inputs_id, parameters)
              .then((response) => {
                if (response) {
                  let message = "Data Saved Successfully";
                  showInfo("Avertissement", message, "#dialogBox .dialog-body");
                }
                return response;
              })
              .catch((error) => {
                return error;
              });
          } else {
            let parameters = newObj;
            await apiCall.createSectionId("api/glycemic_logbooks", this.patient_id, "periods", period.idObject.id, "period_patient_inputs", parameters)
              .then((response) => {
                if (response) {
                  let message = "Data Saved Successfully";
                  showInfo("Avertissement", message, "#dialogBox .dialog-body");
                }
                return response;
              })
              .catch((error) => {
                return error;
              });
          }
        }
      });
    },

    /**
     * Download data from the specified URL.
     *
     * @returns {Promise<string>} The data from the URL.
     */
    async saveEpisode() {

      if (this.newEpisodes.length !== 0) {
        let apiData;
        for (let i = 0; i < this.newEpisodes.length; i++) {

          let episode = this.newEpisodes[i];

          if(!episode.already_sent) {
            let episodeParams = {
              blood_sugar: episode.blood_sugar,
              episode_date: episode.episode_date,
              episode_time: episode.episode_time.slice(0, 5),
            };
            if (episode.id) {
              apiData = await apiCall.updateSection("api/glycemic_logbooks", this.patient_id, "glycemic_episode", episode.id, episodeParams);

            } else {
              apiData = await apiCall.createSection("api/glycemic_logbooks", this.patient_id, "glycemic_episode", episodeParams);
            }

            if(apiData.status == 201) {
              this.newEpisodes[i].already_sent = true;
            }
          }
        }
      }
      return true;

    },
    saveParameters() {
      if (Object.keys(this.newParameters).length !== 0) {
        this.newParameters.patient_inputs.hba1c === null ? delete this.newParameters.patient_inputs.hba1c : '';
        this.newParameters.patient_inputs.weight === null ? delete this.newParameters.patient_inputs.weight : '';
        this.newParameters.patient_inputs.height === null ? delete this.newParameters.patient_inputs.height : ''

        let parameters = {
          date: this.newParameters.date,
          ...this.newParameters.patient_inputs
        }

        if (this.logbookParameters.parameters_patient_inputs.length) {
          let idParameter = this.logbookParameters.parameters_patient_inputs[0].id

          let responseParameterIf = apiCall.updateSectionId("api/glycemic_logbooks", this.patient_id, "glycemic_logbook_parameters", this.logbookParameters.id, "parameters_patient_input", idParameter, parameters);
          return responseParameterIf;

        } else {
          let responseParameterElse = apiCall.createSectionId('api/glycemic_logbooks', this.patient_id, 'glycemic_logbook_parameters', this.logbookParameters.id, 'parameters_patient_input', parameters);
          return responseParameterElse;

        }
      }
    },
    submit() {
      let isRedirectd = false;
      this.savePeriods();
      Promise.allSettled([this.saveParameters(), this.saveEpisode()])
        .then((values) => {
          values.map((ele) => {
            if (ele.status == 'fulfilled') {
              let message = "Data Saved Successfully";
              showInfo("Avertissement", message, "#dialogBox .dialog-body");
              isRedirectd = true;
            } else {
              var errors = Object.values(ele.reason.response.data.errors);
              errors.forEach(function (listError, index) {
                listError.map((item) => {
                  showAlert('Avertissement', item, "#dialogBox .dialog-body");
                });
              });
              isRedirectd = false;
            }
          });
          if (isRedirectd) {
            setTimeout(() => {
              window.location.href = "/patient/";
            }, 2000);
          }
        });
    },
  },
};
</script>

<style >
@media only screen and (max-width: 768px) {
  .cards-scroll {
    overflow: scroll;
  }
}

input#dateLogbook {
  padding: 0;
}

.input.mt-0.mb-3.input-colapsed {
  padding: 0;
}

span.vue__time-picker.time-picker {
  padding-bottom: 13px;
}

span.vue__time-picker.time-picker input {
  border: none;
  border-bottom: 0.1px solid #d9d9d9 !important;
}
</style>
