var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-section" }, [
    _c("p", [
      _vm._v(
        "Pour parrainer un confrère afin qu'il s'inscrive à la plateforme Rafiq Essiha, veuillez rentrer son email ci-dessous :"
      ),
    ]),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "bg-white p-4 rounded-lg",
        attrs: {
          enctype: "multipart/form-data",
          method: "post",
          action: "/professionnel/ws/parrainage_confrere.php",
        },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return ((event) => !_vm.loading && _vm.submit(event)).apply(
              null,
              arguments
            )
          },
        },
      },
      [
        _c(
          "fieldset",
          { attrs: { disabled: _vm.loading } },
          [
            _c("Input", {
              attrs: {
                label: "Email",
                name: "email",
                "custom-margin": true,
                required: "",
              },
              model: {
                value: _vm.form.email,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "email", $$v)
                },
                expression: "form.email",
              },
            }),
            _vm._v(" "),
            _c(
              "InputGroup",
              {
                attrs: {
                  label:
                    "A quel programme souhaitez-vous parrainer votre confrère ?",
                  required: "",
                },
              },
              [
                _c("Radio", {
                  attrs: {
                    value: "hta",
                    name: "program",
                    id: "hta",
                    label: "HTA eCare",
                  },
                  model: {
                    value: _vm.form.program,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "program", $$v)
                    },
                    expression: "form.program",
                  },
                }),
                _vm._v(" "),
                _c("Radio", {
                  attrs: {
                    value: "diab",
                    name: "program",
                    id: "diab",
                    label: "Diab eCare",
                  },
                  model: {
                    value: _vm.form.program,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "program", $$v)
                    },
                    expression: "form.program",
                  },
                }),
                _vm._v(" "),
                _c("Radio", {
                  attrs: {
                    value: "diab-hta",
                    name: "program",
                    id: "diab-hta",
                    label: "Diab eCare & HTA eCare",
                  },
                  model: {
                    value: _vm.form.program,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "program", $$v)
                    },
                    expression: "form.program",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "En cliquant sur ce bouton, il recevra un mail avec toutes les informations lui permettant de s'inscrire à la plateforme"
              ),
            ]),
            _vm._v(" "),
            _c("br"),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "fieldset",
          { attrs: { disabled: _vm.loading } },
          [
            _c("Submit", {
              staticClass: "text-center",
              attrs: { label: "Valider le parrainage" },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }